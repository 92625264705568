/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IFetchableItem,
  IPageModuleBundle,
  IPageModuleBundlesCollection,
  IPageModuleBrandBanners,
  IPageModuleArtists,
  TRelatedContent,
  analyticsProductImpression,
  analyticsProductImpressionMulti,
  PageModuleType,
  IPageModuleFavouriteProducts,
} from '@elc/common';
import { fulfilledResults } from '../../utils/promiseUtils';
import {
  fetchMediaResource,
  fetchSingleBundle,
  getSingleAritst,
  fetchCustomArtists,
} from '../../utils/actionsUtils';

const fetchPageModule = async (
  pageModule: PageModuleType,
  locationParticipants: any[],
  locationVanity: string,
) => {
  switch (pageModule.name) {
    case 'bundle': {
      const bundle = await fetchSingleBundle((pageModule as IPageModuleBundle).store);
      analyticsProductImpression(bundle);

      return { name: 'bundle', item: bundle };
    }
    case 'bundlesCollection': {
      const { primary_store, non_primary_stores } = pageModule as IPageModuleBundlesCollection;

      const looks = await fulfilledResults(
        [primary_store, ...non_primary_stores].map((short_id) => fetchSingleBundle(short_id)),
      );
      analyticsProductImpressionMulti(looks);

      return {
        name: 'bundlesCollection',
        item: {
          looks,
        },
      };
    }
    case 'favouriteProducts': {
      const { single_product_stores, custom_title } = pageModule as IPageModuleFavouriteProducts;
      const singleProductLooks = await fulfilledResults(
        single_product_stores.map((short_id) => fetchSingleBundle(short_id)),
      );
      analyticsProductImpressionMulti(singleProductLooks);
      return {
        name: 'favouriteProducts',
        item: {
          singleProductLooks,
          custom_title,
        },
      };
    }
    case 'brandBanners': {
      const fetchedResources = await fulfilledResults<TRelatedContent>(
        (pageModule as IPageModuleBrandBanners).brand_contents.map((uid: string) =>
          fetchMediaResource(uid),
        ),
      );

      const activeResources = fetchedResources.filter((r) => !r.is_deleted);

      return {
        name: 'banners',
        item: {
          banners: activeResources,
        },
      };
    }
    case 'shearableLink': {
      const fetchedResources = await fulfilledResults<TRelatedContent>(
        (pageModule as IPageModuleBrandBanners).brand_contents.map((uid: string) =>
          fetchMediaResource(uid),
        ),
      );

      const activeResources = fetchedResources.filter((r) => !r.is_deleted);

      return {
        name: 'shearableLink',
        item: {
          banners: activeResources,
        },
      };
    }
    case 'brandVideos': {
      const fetchedResources = await fulfilledResults<TRelatedContent>(
        (pageModule as IPageModuleBrandBanners).brand_contents.map((uid: string) =>
          fetchMediaResource(uid),
        ),
      );

      const activeResources = fetchedResources.filter((r) => !r.is_deleted);

      return {
        name: 'video',
        item: {
          video: activeResources,
        },
      };
    }
    case 'ourStylists': {
      const stylistsConnectedUsersUids = (pageModule as IPageModuleArtists).artists;
      const customArtistsUids = (pageModule as IPageModuleArtists).custom_artists || [];

      const stylistsVanityUrls = locationParticipants
        .filter(({ connected_user }) => stylistsConnectedUsersUids.includes(connected_user?.uid))
        .map((stylist) => stylist.vanity_url);

      const stylistsInfo = await fulfilledResults(
        stylistsVanityUrls.map((vanityUrl) => getSingleAritst(vanityUrl)),
      );

      const customArtists = await fetchCustomArtists(locationVanity);

      const selectedCustomArtists = customArtists.filter((ca) =>
        customArtistsUids.includes(ca.uid),
      );
      const filteredStylists = selectedCustomArtists.concat(stylistsInfo);

      return {
        name: 'ourStylists',
        item: {
          sectionName: pageModule.name,
          stylists: filteredStylists,
        },
      };
    }
    default:
      return Promise.reject();
  }
};

export const fetchLocationPage = createAsyncThunk(
  'locationPage/fetchAllPageElements',
  async ({
    locationVanity,
    pageModules,
    locationParticipants,
  }: {
    locationVanity: string;
    pageModules: PageModuleType[];
    locationParticipants: any[];
  }) => {
    try {
      const page = await fulfilledResults(
        pageModules.map((module) => fetchPageModule(module, locationParticipants, locationVanity)),
      );

      return { locationVanity, page };
    } catch (error) {
      return Promise.reject(error);
    }
  },
  {
    condition: ({ locationVanity }, { getState }) => {
      const { locationPage } = getState() as { locationPage: IFetchableItem<ILocationPage> };
      if (
        locationPage.item.locationVanity === locationVanity &&
        locationPage.item.page.length !== 0
      ) {
        return false;
      }
    },
  },
);
