import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBundle, IFetchableItem, TLook } from '@elc/common';

export const fetchBundle = createAsyncThunk(
  'bundle/fetchBundleById',
  async (
    {
      shortId,
      token,
    }: {
      shortId: string;
      token?: string | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: response } = await getBundle(shortId, token);

      if (response) {
        return response.store;
      }
      throw Error();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return rejectWithValue((error as any).response.status);
    }
  },
  {
    condition: ({ shortId }, { getState }) => {
      const { bundle } = getState() as { bundle: IFetchableItem<TLook> };
      if (bundle.item.short_id === shortId) {
        return false;
      }
    },
  },
);
