import { createSelector } from '@reduxjs/toolkit';
import { TCartItem } from '../../typings';
import { MainState } from '../types';

const selectSelf = (state: MainState) => state.basket;

export const basketQuantitySelector = createSelector(selectSelf, (state) =>
  state.items.reduce((acc: number, item: TCartItem) => acc + item.quantity, 0),
);

export const basketSelector = createSelector(selectSelf, (state) => state.items);

export const createBasketProductListSelector = (basketItemProperty: string) =>
  createSelector(selectSelf, (state) =>
    state.items.reduce(
      (acc: Array<Record<string, number>>, item: TCartItem) => [
        ...acc,
        { [item[basketItemProperty]]: item.quantity },
      ],
      [],
    ),
  );

export const basketSubtotalSelector = createSelector(selectSelf, (state) =>
  state.items.reduce(
    (acc: number, item: TCartItem) => acc + item.quantity * Number.parseFloat(item.retail_price),
    0,
  ),
);

export const lastAddedItemsSelector = createSelector(selectSelf, (state) => state.lastAddedItems);

export const lookFromLastItemWasAddedSelector = createSelector(
  selectSelf,
  (state) => state.lookFromLastItemWasAdded,
);

export const basketArtistIdSelector = createSelector(selectSelf, (state) => state.artistId);
export const isBasketPendingSelector = createSelector(selectSelf, (state) => state.isPending);
