import styled from 'styled-components';
import { transparentButton } from '../../../../utils/styled-components/mixins';

export const Link = styled.button<{ $color: string }>`
  ${transparentButton}
  display: flex;
  align-items: center;
  color: ${(props) => props.$color};
  margin-left: 8px;
  padding: 0;
  white-space: nowrap;
  font-size: 16px;

  & svg {
    margin-right: 3px;
  }
`;
