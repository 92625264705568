import React from 'react';
import { useNavigate } from 'react-router-dom';
import { E2EWrapper } from '../../E2EWrapper/E2EWrapper';
import { TLook } from '../../..';

import * as Ui from './LookSlide.styles';

export type LookSlideProps = {
  look: TLook;
  locationVanityUrl: string;
};

export const LookSlide = ({ look, locationVanityUrl, ...styles }: LookSlideProps) => {
  const navigate = useNavigate();

  const redirectToLook = () => {
    navigate(`/salon/${locationVanityUrl}/look/${look.short_id}`, {
      state: {
        from: 'salon',
      },
    });
  };
  return (
    <E2EWrapper id={`salonLook-${look.name}`} {...styles}>
      <Ui.Slide role="button" onClick={redirectToLook}>
        <Ui.CoverPhotoWrapper>
          <Ui.Image src={look.pictures[0]} />
        </Ui.CoverPhotoWrapper>
        <Ui.Name>{look.name}</Ui.Name>
      </Ui.Slide>
    </E2EWrapper>
  );
};
