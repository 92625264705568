import {
  breakpoints,
  CheckoutContext,
  EBrand,
  Footer,
  SalonTopbar,
  Topbar,
  useCurrentPath,
} from '@elc/common';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Outlet, RouteObject } from 'react-router-dom';
import AvedaLogoWhite from '../../assets/aveda-logo-white.png';
import footerLinks from '../../constants/footerLinks';
import { StoreLayoutContext } from '../../hooks/useStoreLayoutContext';
import { Basket, BasketIcon } from '../basket/basket';
import { Page, Wrapper } from './storeLayout.styles';

import { useAvedaRedirect } from '../../hooks/useAvedaRedirect';
import { useAvedaTopBar } from '../../hooks/useAvedaTopBar';
import { useIsLookPage } from '../../hooks/useIsLookPage';

const StoreLayout: FC = () => {
  const { t } = useTranslation(['app']);

  const [salonLogoUrl, setLogo] = useState('');

  const paths = useCurrentPath();
  const isSmallerThenMd = useMediaQuery({
    query: `(max-width: ${breakpoints.md}px)`,
  });
  const isLookPage = useIsLookPage();
  const redirectToAveda = useAvedaRedirect();
  const { showAvedaTopBar, ready, salonLogo } = useAvedaTopBar();
  const handleBannerClick = () => redirectToAveda({ source: 'banner' });
  const handleBannerClickContext = useCallback((path: string) => {
    redirectToAveda({ path, source: 'banner' });
  }, []);
  const storeContext = useMemo(() => ({ setLogo, showAvedaTopBar }), [showAvedaTopBar]);

  const shouldHideFooter =
    paths &&
    isSmallerThenMd &&
    paths.find((path) => (path.route as RouteObject & { name: string }).name === 'Look');

  const isTopBarLinkVisible =
    paths &&
    paths?.find((path) =>
      ['Look', 'Product'].includes((path.route as RouteObject & { name: string }).name),
    );

  return (
    <StoreLayoutContext.Provider value={storeContext}>
      <Wrapper>
        {/* eslint-disable-next-line */}
        {ready && showAvedaTopBar ? (
          <SalonTopbar fullWidth={isLookPage} logo={salonLogo} onClick={redirectToAveda}>
            <BasketIcon />
          </SalonTopbar>
        ) : ready ? (
          <Topbar
            linkVisible={!isTopBarLinkVisible}
            text={t('topbar.shop')}
            onClick={handleBannerClick}
            brand={EBrand.Aveda}
            logo={salonLogoUrl}
          >
            <BasketIcon />
          </Topbar>
        ) : null}

        <Basket />

        <CheckoutContext.Provider value={handleBannerClickContext}>
          <Page noPadding>
            <Outlet />
          </Page>
        </CheckoutContext.Provider>
      </Wrapper>
      {!shouldHideFooter && <Footer logo={AvedaLogoWhite} links={footerLinks} showDisclaimer />}
    </StoreLayoutContext.Provider>
  );
};

export default StoreLayout;
