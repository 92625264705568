import {
  getBundle,
  getMediaResource,
  getStore,
  TLook,
  TRelatedContent,
  getCustomArtists,
  TArtist,
} from '@elc/common';
import { TAvedaArtist } from '../@types/AvedaArtist';

export const fetchSingleBundle = async (short_id: string): Promise<TLook> => {
  const axiosResponse = await getBundle(short_id);
  const bundle = axiosResponse.data as {
    success: boolean;
    store: TLook;
  };

  if (!bundle.store) {
    throw Error();
  }
  return bundle.store;
};

export const getSingleAritst = async (artistVanity: string): Promise<TAvedaArtist> => {
  const { data } = await getStore(artistVanity);

  if (!data) {
    throw Error();
  }
  return data as TAvedaArtist;
};

export const fetchMediaResource = async (uid: string): Promise<TRelatedContent> => {
  const { data } = await getMediaResource(uid);

  if (!data) {
    throw Error();
  }

  return data as TRelatedContent;
};

export const fetchCustomArtists = async (locationVanity: string) => {
  const { data } = await getCustomArtists(locationVanity);

  if (!data) {
    throw Error();
  }

  return data as TArtist[];
};
