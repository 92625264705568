import React, { FC } from 'react';
import { TUseCheckoutFlowType } from '../../../..';

import * as Ui from './Promo.styles';

type PromoProps = {
  onClick: TUseCheckoutFlowType;
  href: string;
  isTextHidden: boolean;
  text: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const Promo: FC<PromoProps> = ({ onClick, href, isTextHidden, text, Icon }) => (
  <Ui.Link
    $color="black"
    onClick={() =>
      onClick({
        path: href,
        source: 'header',
      })
    }
  >
    <Icon style={{ width: '1rem', height: '1rem' }} />
    {isTextHidden || text}
  </Ui.Link>
);
