import { ILocation } from '..';

export const getLocationAddress = (location: ILocation) =>
  ` ${location.address.street_name.toLocaleLowerCase()}, ${location.address.post_town.toLowerCase()}, ${location.address.state?.toUpperCase()} ${
    location.address.post_code
  }`;

export const removeCountryFromAddress = (address: string) => {
  const index = address.lastIndexOf(',');

  return address.substr(0, index);
};
