import React, { useMemo } from 'react';
import Swiper, { ReactIdSwiperChildren, ReactIdSwiperProps } from 'react-id-swiper';
import { Pagination, Navigation } from 'swiper';
import { useMediaQuery } from 'react-responsive';

import { breakpoints } from '../../utils/rwd';
import * as Ui from './NewCarousel.styles';
import 'swiper/swiper.min.css';

export type TCarouselProps = {
  carouselParams?: ReactIdSwiperProps;
  children: ReactIdSwiperChildren;
};

const NewCarousel = ({ carouselParams = {}, children }: TCarouselProps) => {
  const isSmallerThenLg = useMediaQuery({
    query: `(max-width: ${breakpoints.lg}px)`,
  });

  const paramsMemoized = useMemo(() => {
    const params = {
      slidesPerView: isSmallerThenLg ? 1.5 : 3,
      spaceBetween: isSmallerThenLg ? 10 : 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      rebuildOnUpdate: true,
    };

    return { ...params, ...carouselParams };
  }, [isSmallerThenLg, carouselParams]);

  return (
    <Ui.CarouselWrapper>
      <Swiper modules={[Pagination, Navigation]} {...paramsMemoized}>
        {children}
      </Swiper>
    </Ui.CarouselWrapper>
  );
};

export { NewCarousel };
