import React, { FC } from 'react';

export const Spinner: FC = () => (
  <div
    style={{ width: '5rem', height: '5rem' }}
    className="spinner-border text-black"
    role="status"
  >
    <span className="sr-only" />
  </div>
);

export default Spinner;
