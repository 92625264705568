import React from 'react';
import { TLook } from '../../typings';
import { NewCarousel } from '../NewCarousel/NewCarousel';
import { LookSlide } from './components/LookSlide';
import * as Ui from './LooksCollection.styles';

export type LooksCollectionType = {
  looks: Array<TLook>;
  locationVanityUrl: string;
};

export const LooksCollection = ({ looks, locationVanityUrl }: LooksCollectionType) => {
  if (looks.length) {
    return (
      <Ui.Wrapper>
        <Ui.Container>
          <Ui.SectionTitle>Shoppable looks</Ui.SectionTitle>
          <Ui.CarouselWrapper>
            <NewCarousel>
              {looks.map((look: TLook) => (
                <LookSlide key={look.short_id} look={look} locationVanityUrl={locationVanityUrl} />
              ))}
            </NewCarousel>
          </Ui.CarouselWrapper>
        </Ui.Container>
      </Ui.Wrapper>
    );
  }
  return null;
};
