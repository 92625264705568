import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArtistLocation } from '@elc/common';

import { bundleSelector } from '../store/selectors/bundle.selectors';
import { artistSelector } from '../store/selectors/artist.selectors';
import { useIsLookPage } from './useIsLookPage';
import { useIsArtistPage } from './useisArtistPage';

const getSalonLogo = (locations: ArtistLocation[], salonVanityUrl: string): string => {
  let salonLogo = '';
  if (locations.length === 1) {
    salonLogo = locations[0].logo;
  }
  if (locations.length > 1) {
    if (salonVanityUrl) {
      const index = locations.findIndex((location) => location.vanity_url === salonVanityUrl);
      salonLogo = locations[index].logo;
    } else {
      salonLogo = locations[0].logo;
    }
  }

  return salonLogo;
};

export const useAvedaTopBar = (): {
  ready: boolean;
  showAvedaTopBar: boolean;
  salonLogo: string;
} => {
  const { state } = useLocation();
  const isArtistPage = useIsArtistPage();
  const isLookPage = useIsLookPage();
  const { locationName } = useParams<{ locationName: string }>();

  const artist = useSelector(artistSelector);
  const look = useSelector(bundleSelector);

  const salonVanityUrl =
    locationName || (state as unknown as { salonVanityUrl: string })?.salonVanityUrl;

  let ready = false;
  let showAvedaTopBar = false;
  let salonLogo = '';

  if (isLookPage) {
    ready = !!look.short_id;
    showAvedaTopBar = !!look.owner.locations.length;
    salonLogo = getSalonLogo(look.owner.locations, salonVanityUrl);
  }

  if (isArtistPage) {
    ready = !!artist.id;
    showAvedaTopBar = !!artist.locations.length;
    salonLogo = getSalonLogo(artist.locations, salonVanityUrl);
  }

  return {
    ready,
    showAvedaTopBar,
    salonLogo,
  };
};
