import {
  basketAction,
  ColorPicker,
  EVariant,
  formatPrice,
  getSizeName,
  TCartItem,
  TextTruncate,
} from '@elc/common';
import React, { FC } from 'react';
import { useThunkDispatch } from '../../store';

import {
  BasketItemContent,
  BasketItemImage,
  BasketItemWrapper,
  CloseIcon,
  ColorName,
  Quantity,
  QuantityButton,
  QuantityWrapper,
  Size,
} from './basket.styles';

export const Item: FC<{ item: TCartItem }> = ({ item }) => {
  const dispatch = useThunkDispatch();

  const handlePlusClick = () => {
    dispatch(
      basketAction.addToBasket({
        ...item,
        quantity: 1,
      }),
    );
  };

  const handleMinusClick = () => {
    dispatch(basketAction.lowerTheAmount(item));
  };

  const handleDeleteClick = () => {
    dispatch(basketAction.removeFromBasket(item));
  };

  return (
    <BasketItemWrapper>
      <CloseIcon onClick={handleDeleteClick} />

      <BasketItemImage>
        <img alt="Product thumbnail" src={`${item.photo_url}`} />
      </BasketItemImage>
      <BasketItemContent>
        {/* eslint-disable-next-line react/no-danger */}
        <p className="name" dangerouslySetInnerHTML={{ __html: item.name }} />
        <p className="description">
          <TextTruncate
            line={2}
            element="span"
            truncateText="…"
            text={item.description}
            textTruncateChild={<span>Read on</span>}
          />
        </p>
        {item.variant === EVariant.SIZE ? (
          <Size>{getSizeName(item.size.name)}</Size>
        ) : (
          <div className="my-2">
            <ColorPicker
              colors={[{ ...item.color, skuUid: item.uid, inventory: item.inventory }]}
              onClick={() => null}
              defaultActive={item.uid}
            />
            <ColorName color={item.color.hex_value}>{item.color.name}</ColorName>
          </div>
        )}
        <p className="price">{formatPrice(item.retail_price, item.currency)}</p>
        <QuantityWrapper>
          <QuantityButton onClick={handleMinusClick}>-</QuantityButton>
          <Quantity>{item.quantity}</Quantity>
          <QuantityButton onClick={handlePlusClick}>+</QuantityButton>
        </QuantityWrapper>
      </BasketItemContent>
    </BasketItemWrapper>
  );
};
