import { useQueryParams } from '@elc/common';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';

import { locationSelector } from '../store/selectors/location.selectors';
import { artistSelector } from '../store/selectors/artist.selectors';
import { pages } from '../routes/config';

export const useLookBackNavigation = (): {
  label: string;
  path: string;
} => {
  const { state } = useLocation();

  const {
    token = '',
    owner_token: ownerPageToken,
    feedback: addFeedback,
    preview,
  } = useQueryParams();

  const location = useSelector(locationSelector);
  const artist = useSelector(artistSelector);
  const isLookSalonPage = useMatch(pages.LOOK_FROM_LOCATION) !== null;
  const fromSalon = (state as unknown as { from: string })?.from === 'salon';
  const locationDataWithoutArtistData = !!location.vanity_url && !artist.vanity_url;
  const isProductPage = useMatch(pages.PRODUCT) !== null;

  if (fromSalon && (locationDataWithoutArtistData || isLookSalonPage || isProductPage)) {
    return {
      label: location.name,
      path: `/salon/${location.vanity_url}`,
    };
  }

  const shouldAddToken = ownerPageToken || (token && artist.profile_page_token);
  let artistPageUrl = `/a/${artist.vanity_url}${
    shouldAddToken ? `?token=${ownerPageToken || artist.profile_page_token}` : ''
  }`;
  if (addFeedback) {
    const feedSeparator = artistPageUrl.indexOf('?') !== -1 ? '&' : '?';
    artistPageUrl = `${artistPageUrl}${feedSeparator}feedback=true`;
  }
  if (preview) {
    const separator = artistPageUrl.indexOf('?') !== -1 ? '&' : '?';
    artistPageUrl = `${artistPageUrl}${separator}preview=true`;
  }

  return {
    label: artist.fullName,
    path: artistPageUrl,
  };
};
