import React, { FC, useEffect, useState } from 'react';
import {
  ProductPage,
  TProduct,
  CenteredCard,
  StatusWrapper,
  formatPrice,
  useAddProductToCart,
  useQueryParams,
  SalonTopbar,
} from '@elc/common';
import { useMatch, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Metatags } from '../components/Metatags';

import { fetchBundle } from '../store/actions/bundle.actions';
import { fetchArtist, fetchPrivateArtistProfile } from '../store/actions/artist.actions';
import { useGlobalState, useThunkDispatch } from '../store';
import NoImage from '../assets/img/aveda.png';
import { increaseEntriesCounter } from '../utils/increaseEntriesCounter';
import useProductBackNavigation from '../hooks/useProductBackNavigation';
import { useAvedaRedirect } from '../hooks/useAvedaRedirect';
import { locationSelector } from '../store/selectors/location.selectors';
import { BasketIcon } from '../components/basket/basket';
import { fetchLocation } from '../store/actions/location.actions';
import { pages } from '../routes/config';

type ProductRouteParams = { vanityUrl: string; shortId: string; locationName?: string };

const ProductScreen: FC = () => {
  const { vanityUrl = '', shortId = '', locationName = '' } = useParams<ProductRouteParams>();
  const { owner_token: ownerPageToken } = useQueryParams();
  const location = useSelector(locationSelector);
  const fromSalon = useMatch(pages.PRODUCT_FROM_LOCATION); // returns null when not matched
  const salonLogo = fromSalon ? location.logo : undefined;

  const dispatch = useThunkDispatch();
  const { status, item: artist } = useGlobalState((state) => state.artist);
  const { item: look } = useGlobalState((state) => state.bundle);
  const { t } = useTranslation(['app', 'metatags']);
  const lookBackNavigation = useProductBackNavigation();
  const redirectAveda = useAvedaRedirect();

  const [product, setProduct] = useState<TProduct | null>(null);

  useEffect(() => {
    // Don't check if user is archived when coming from location page
    if (fromSalon == null) {
      if (ownerPageToken) {
        dispatch(fetchPrivateArtistProfile({ vanity_url: vanityUrl, token: ownerPageToken }));
      } else {
        dispatch(fetchArtist(vanityUrl));
      }
    }
    if (fromSalon && !location.id) {
      dispatch(fetchLocation(locationName));
    }
    dispatch(fetchBundle({ shortId, token: ownerPageToken }));
  }, [shortId, vanityUrl, fromSalon]);

  useEffect(() => {
    if (look.products.length > 0) {
      setProduct(look.products[0]);
      increaseEntriesCounter(look._id, 'SINGLE_PRODUCT_PAGE');
    }
  }, [look]);

  const addProductToCart = useAddProductToCart();

  if (!product) {
    return null;
  }

  const metaDescription = t('metatags:favoriteProductDescription', {
    ownerName: look.owner.full_name,
    productDescription: product.description,
    price: formatPrice(product.primary_sku.retail_price, product.currency),
  });

  const subSkusProductCodes = product.sub_skus.map((subSku) => subSku.product_code).join(', ');
  const keywords = `${t('metatags:keywords')}, ${product.name}, ${subSkusProductCodes}`;

  return (
    <StatusWrapper
      status={status}
      defaultNotFound={
        <CenteredCard title={t('app:errors.notFound')} content={t('app:errors.artistNotFound')} />
      }
    >
      <>
        <Metatags
          title={product.name}
          description={metaDescription}
          image={product.primary_sku.images[0]}
          keywords={keywords}
          noindex
        />
        <ProductPage
          product={product}
          onAddToCart={addProductToCart(shortId)}
          artist={artist}
          noImage={NoImage}
          navigationProps={lookBackNavigation}
          TopBar={
            <SalonTopbar onClick={redirectAveda} logo={salonLogo}>
              <BasketIcon />
            </SalonTopbar>
          }
        />
      </>
    </StatusWrapper>
  );
};

export { ProductScreen };
