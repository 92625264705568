import React from 'react';
import { ImageSkeleton, Skeleton, SkeletonRectangle } from '../../Skeleton/Skeleton';
import * as Ui from '../SalonHeader.styles';

export const SalonHeaderSkeleton = () => (
  <Ui.Wrapper>
    <Ui.Container>
      <Ui.ImageCol>
        <div
          style={{
            position: 'relative',
            objectFit: 'cover',
            width: '100%',
            paddingBottom: '56.25%',
          }}
        >
          <ImageSkeleton />
        </div>
      </Ui.ImageCol>

      <Ui.ContentColumn>
        <Ui.SalonName>
          <Skeleton height={36} style={{ minWidth: '200px', maxWidth: '300px', width: '100%' }}>
            <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
          </Skeleton>
        </Ui.SalonName>
        <Ui.Classification>
          <Skeleton height={23} style={{ minWidth: '100px', maxWidth: '200px', width: '100%' }}>
            <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
          </Skeleton>
        </Ui.Classification>
        <Ui.ContactData>
          <p>
            <Skeleton height={23} style={{ minWidth: '100px', maxWidth: '200px', width: '100%' }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
          </p>
          <p>
            <Skeleton height={23} style={{ minWidth: '100px', maxWidth: '200px', width: '100%' }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
          </p>
        </Ui.ContactData>
        <Ui.IconsContainer>
          <Ui.ContactIcon>
            <Skeleton height={23} style={{ width: '25px', marginBottom: 10 }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
            <Skeleton height={23} style={{ width: '40px' }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
          </Ui.ContactIcon>

          <Ui.ContactIcon>
            <Skeleton height={23} style={{ width: '25px', marginBottom: 10 }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
            <Skeleton height={23} style={{ width: '40px' }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
          </Ui.ContactIcon>

          <Ui.ContactIcon>
            <Skeleton height={23} style={{ width: '25px', marginBottom: 10 }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
            <Skeleton height={23} style={{ width: '40px' }}>
              <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
            </Skeleton>
          </Ui.ContactIcon>
        </Ui.IconsContainer>
      </Ui.ContentColumn>
    </Ui.Container>
  </Ui.Wrapper>
);
