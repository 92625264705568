import React from 'react';
import { Skeleton, SkeletonRectangle } from '../../Skeleton/Skeleton';
import { ProductListSkeleton } from '../../ProductList/skeleton/ProductList.skeleton';
import * as Ui from '../Look.styles';

export const LookSkeleton = () => (
  <Ui.Wrapper className="singleBundle_wrapper">
    <Ui.ClickableHeader to="#">
      <Ui.Header>
        <Skeleton width={100} height={15} viewBox="0 0 100 15">
          <rect x="0" y="0" rx="2" ry="2" width="100" height="15" />
        </Skeleton>

        <div style={{ marginTop: '10px' }}>
          <Skeleton style={{ width: '75%', height: '30px' }}>
            <SkeletonRectangle width="90%" height="13px" />
            <SkeletonRectangle width="100%" height="13px" y="17" />
          </Skeleton>
        </div>
      </Ui.Header>
    </Ui.ClickableHeader>

    <Ui.Container>
      <Ui.ImageContainer>
        <Ui.Image>
          <Skeleton style={{ width: '100%', height: '100%' }}>
            <rect style={{ width: '100%', height: '100%' }} x="0" y="0" rx="2" ry="2" />
          </Skeleton>
        </Ui.Image>
      </Ui.ImageContainer>
      <Ui.ProductsListWrapper>
        <ProductListSkeleton />
      </Ui.ProductsListWrapper>
    </Ui.Container>
  </Ui.Wrapper>
);
