import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '..';

const selectSelf = (state: GlobalState) => state.location;

export const locationSelector = createSelector(selectSelf, (state) => state.item);

export const locationRelativeHrefSelector = createSelector(
  selectSelf,
  (state) => `/salon/${state.item.vanity_url}`,
);
