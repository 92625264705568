export function isImageTransparentAndWithWhiteEdges(imgUrl: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) return reject();

      ctx.drawImage(img, 0, 0, img.width, img.height);
      const { data } = ctx.getImageData(0, 0, img.width, img.height);

      let isTransparent = false;
      let whiteBalance = 0;
      for (let i = 0; i < data.length; i += 4 * img.width) {
        let j = i;
        while (j < i + 4 * img.width && data[j + 3] === 0) {
          isTransparent = true;
          j += 4;
        }
        if (j < i + 4 * img.width) {
          if (data[j] >= 220 && data[j + 1] >= 220 && data[j + 2] >= 220) {
            whiteBalance++;
          } else {
            whiteBalance--;
          }
        }

        let k = i + 4 * img.width - 4;
        while (k >= i + 4 && data[k + 3] === 0) {
          isTransparent = true;
          k -= 4;
        }

        if (data[k] >= 220 && data[k + 1] >= 220 && data[k + 2] >= 220) {
          whiteBalance++;
        } else {
          whiteBalance--;
        }
      }
      return resolve(isTransparent && whiteBalance > 0);
    };

    img.onerror = reject;
    img.src = imgUrl;
  });
}
