import styled from 'styled-components';
import { StarRating as Stars } from '../StarRating/StarRating';
import Picker from '../ColorPickerExtended/ColorPickerExtended';
import { Button } from '../Button/Button';
import { Tabs as TabComponent } from '../Tabs/Tabs';
import { media, ShortArtistProfileSection as SAP } from '../..';

export const PageWrapper = styled.div`
  background: white;
`;

export const Container = styled.div`
  max-width: 1440px;
  padding: 20px;
  background: white;
  margin: 0 auto;
  ${media.md.down`
    padding: 0 12px;
  `}
`;

export const ShortArtistProfileSection = styled(SAP)`
  margin: 12px 0;
  padding: 0;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 10px;
  ${media.md.down`
    flex-direction: column;
  `}
`;

export const ImageGalleryColumn = styled.div`
  width: 66%;
  padding-right: 20px;
  ${media.md.down`
    width: 100%;
    padding-right: 0px;
  `}
`;

export const ProductDetailsColumn = styled.div`
  flex: 1;
`;

export const StarRating = styled(Stars)`
  margin: 10px 0 38px 0;
  ${media.md.down`
    display: none;
  `}
`;

export const ProductNameWrapper = styled.div.attrs({
  className: 'd-flex justify-content-between align-items-end',
})`
  margin: 6px 0;
  ${media.md.down`
    margin: px 0;
  `}
`;

export const ProductName = styled.h1`
  font-size: 24px;
  line-height: 1.25;
  margin: 0;
  ${media.md.down`
  font-size: 20px;
  line-height: 24px;
  `}
`;

export const Price = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold; // czcionka bold
  padding-left: 12px;
`;

export const Size = styled.p`
  font-weight: bold; // czcionka bold
  margin: 0;
  line-height: 2;
  color: ${({ theme }) => theme.colors.grey};
`;

export const VariantPicker = styled(Picker)`
  padding-top: 10px;
`;

export const Description = styled.h2`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  padding-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const AddToBagButton = styled(Button)`
  margin-top: 32px;
`;

export const Tabs = styled(TabComponent)`
  margin-top: 36px;

  div {
    color: var(--bs-body-color);
  }
`;

export const VariantSearchWrapper = styled.div`
  margin: 15px 0 30px 0;
`;
