/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react';

interface IStoreLayoutContext {
  setLogo: (url: string) => void;
  showAvedaTopBar: boolean;
}

export const StoreLayoutContext = createContext<IStoreLayoutContext | null>(null);

export const useStoreLayoutContext = (): IStoreLayoutContext => {
  const context = useContext(StoreLayoutContext);
  if (!context) {
    throw new Error('Use store layout context inside provider');
  }
  return context;
};
