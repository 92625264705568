import React from 'react';
import { Helmet } from 'react-helmet';
import { extractContentFromHtml } from '../../utils/extractContentFromHtml';
import { TLook } from '../../typings';
import { getBundleSchema } from './schemas/getBundleSchema';

export type TMetatags = {
  title?: string;
  description?: string;
  keywords?: string;
  siteName?: string;
  type?: 'bundle';
  model?: TLook;
  noindex?: boolean;
};

const Metatags: React.FC<TMetatags> = ({
  title,
  description = '',
  keywords,
  siteName,
  model,
  type,
  children,
  noindex = false,
}) => {
  let schema = {};

  if (type && model) {
    switch (type) {
      case 'bundle': {
        schema = getBundleSchema(model);
        break;
      }
      default:
        break;
    }
  }
  const rawDescription = extractContentFromHtml(description);

  return (
    <Helmet script={[schema]}>
      <title>{title}</title>
      <meta name="description" content={rawDescription} />
      <meta name="keywords" content={keywords} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={rawDescription} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={rawDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      {noindex && <meta name="robots" content="noindex" />}
      {children}
    </Helmet>
  );
};

export { Metatags };
