import React from 'react';
import { avedaTheme, BrandContext, EBrand } from '@elc/common';
import { ThemeProvider } from 'styled-components';
import Routes from './routes/routes';
import { Metatags } from './components/Metatags';
import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App: React.FC = () => (
  <ThemeProvider theme={avedaTheme}>
    <BrandContext.Provider value={EBrand.Aveda}>
      <Metatags />
      <Routes />
    </BrandContext.Provider>
  </ThemeProvider>
);

export default App;
