export const pages = {
  ROOT: '/',
  BUNDLE: '/look/:shortId',
  LOOK_FROM_ARTIST: '/a/:artistName/look/:shortId',
  LOOK_FROM_LOCATION: '/salon/:locationName/look/:shortId',
  ARTIST_FROM_LOCATION: '/salon/:locationName/a/:vanity_url',
  PRODUCT_FROM_LOCATION: '/salon/:locationName/a/:vanityUrl/p/:shortId',
  ARTIST: '/a/:vanity_url',
  ABOUT: '/about',
  FAQ: '/customer-service-faq',
  FAQ_CONTENT_CREATORS: '/faq',
  CONTACT: '/contact',
  LOCATION: '/salon/:vanity_url',
  TERMS_OF_SERVICE: '/terms/:tabName',
  LEARNING_CENTER: '/how-to-hub',
  LEARNING_CENTER_POST: '/how-to-hub/posts/:postUid',
  FROM_LINK: '/from-link/:slug',
  PRODUCT: 'a/:vanityUrl/p/:shortId',
  SALON_GROUP: 'g/:vanityUrl',
  DELETE_ACCOUNT: '/delete-account',
};
