import React, { useEffect, useRef, useState } from 'react';
import { sanitize } from 'dompurify';
import * as Ui from './HtmlTruncatedText.styles';
import { removeText, removeChildren, isHTML, parseHTML } from './HtmlTruncatedTextUtils';
import { HtmlShowMore } from './HtmlShowMore';

type HtmlTruncatedTextProps = {
  html: string;
  pageUrl?: string;
  sentencesCount?: number;
  elementsCount?: number;
  showMore?: boolean;
  expanded?: boolean;
};

const HtmlTruncatedText = ({
  html,
  pageUrl = '',
  sentencesCount = 5,
  elementsCount = 2,
  showMore = true,
  expanded = false,
}: HtmlTruncatedTextProps) => {
  // the most ugly workaround I ever made, have to be deleted when
  // we get rid off react-native-pell-rich-editor on mobile side
  // TO DO: delete it
  const [onlyBodyFromHtml] = html?.includes('<body>')
    ? html.substring(html.lastIndexOf('</style>')).split('</body>')
    : [html];
  const [readMore, setReadMore] = useState(false);
  const [displayedHtml, setDisplayedHtml] = useState('');

  const truncatedContent = useRef('');
  // this value is used to decide wether orginal html should be used or the truncated one
  // so it cannot be state as those changes should be visible immidiatelly
  const contentRemoved = useRef(false);

  const handleHTMLTruncation = () => {
    // using element which is displayed to calculate if text should be be truncated leads to bugs because
    // if text (html) changes it still contains previous version. So the dummy element should be created, used
    // to calcualtions and then removed

    const tempElement = document.createElement('div');
    const htmlNodes = parseHTML(onlyBodyFromHtml);

    htmlNodes.forEach((node) => {
      tempElement.appendChild(node);
    });

    const removedElement = removeChildren(tempElement, elementsCount);

    if (removedElement) {
      contentRemoved.current = true;
      truncatedContent.current = tempElement.innerHTML;
    }
    tempElement.remove();
  };

  const handleTextTruncation = () => {
    const truncatedText = removeText(html, sentencesCount);

    if (html !== truncatedText) {
      contentRemoved.current = true;
      truncatedContent.current = truncatedText;
    }
  };

  useEffect(() => {
    // clear old values
    contentRemoved.current = false;
    truncatedContent.current = '';

    if (!html) {
      setDisplayedHtml(''); // If there was an text and it changed to empty string it should also be removed from the screen
      return;
    }

    if (isHTML(html)) {
      handleHTMLTruncation();
    } else {
      handleTextTruncation();
    }

    if (!expanded && contentRemoved.current) {
      setReadMore(true);
      setDisplayedHtml(truncatedContent.current);
    } else {
      setDisplayedHtml(html);
    }
  }, [html]);

  return (
    <>
      <Ui.InlineStylesOverridingDiv
        dangerouslySetInnerHTML={{
          __html: sanitize(displayedHtml),
        }}
      />
      <HtmlShowMore
        initialReadMore={readMore}
        showMore={showMore}
        orginalHtml={onlyBodyFromHtml}
        truncatedHtml={truncatedContent.current}
        setDisplayedHtml={setDisplayedHtml}
        pageUrl={pageUrl}
        contentRemoved={contentRemoved.current}
      />
    </>
  );
};

export { HtmlTruncatedText };
