import {
  TUseCheckoutFlowType,
  basketAction,
  basketSelector,
  createBasketProductListSelector,
  getEnvVariable,
  lookFromLastItemWasAddedSelector,
  basketArtistIdSelector,
} from '@elc/common';
import { takeWhile } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { pages } from '../routes/config';
import { artistSelector, promoCodeSelector } from '../store/selectors/artist.selectors';
import { locationSelector } from '../store/selectors/location.selectors';
import { removeEmpty } from '../utils/removeEmpty';
import { addQueryParamToUrl } from '../utils/addQueryParamToUrl';

// DO NOT SKIP www, without it aveda will redirect to www. and double encode parameters

const URL =
  getEnvVariable('BUILD_TYPE') === 'production'
    ? 'https://www.aveda.com/shared/add_to_bag.tmpl'
    : 'https://e.aveda.na.us.stage.ncsa.elcdev.net/shared/add_to_bag.tmpl';

const removeProtocol = (url: string): string =>
  url.startsWith('http://') ? url.replace('http://', '') : url.replace('https://', '');

const removeQueryParams = (url: string): string => takeWhile(url, (char) => char !== '?').join('');

const prepareUrl = (url: string): string => removeProtocol(removeQueryParams(url));

const objectToQueryParams = (obj: Record<string, string>, withEncoding = false): string =>
  Object.entries(obj)
    .map(([key, value]) => {
      const shouldEncode = withEncoding && key !== 'SKU_BASE_ID';
      return `${key}=${shouldEncode ? encodeURIComponent(value) : value}`;
    })
    .join('&');

const getProductQuery = (product: { [key: string]: number }): string => {
  const value = Object.values(product)[0];
  const key = Object.keys(product)[0];

  return Array(value).fill(key).join();
};

const useCheckoutFlow = (): TUseCheckoutFlowType => {
  let redirectUrl = '';
  const dispatch = useDispatch();
  const products = useSelector(createBasketProductListSelector('sku'));
  const artistId = useSelector(basketArtistIdSelector);

  const basket = useSelector(basketSelector);
  const salon = useSelector(locationSelector);
  const artist = useSelector(artistSelector);
  const promoCode = useSelector(promoCodeSelector);
  const lookFromLastItemWasAdded = useSelector(lookFromLastItemWasAddedSelector);
  const isArtistPage = useMatch(pages.ARTIST) !== null;
  const isSalonPage = useMatch(pages.LOCATION) !== null;
  const isLookSalonPage = useMatch(pages.LOOK_FROM_LOCATION) !== null;
  const isLookFromArtistPage = useMatch(pages.LOOK_FROM_ARTIST) !== null;
  const artistID =
    isArtistPage && Array.isArray(artistId) ? artist.publishing_id || artist.artist_id : artistId;

  return (args = {}) => {
    const { path = URL, source = 'checkout' } = args;

    const endpoint = path.startsWith('/') ? `${URL}${path}` : path;
    const welcomeParam = products.length ? '?welcome=true' : '';

    // eslint-disable-next-line
    const queryParams: any = {
      SKU_BASE_ID: '',
      label: isSalonPage || isLookSalonPage ? salon.name : artist.name,
      SalonID: '',
      redirect: `${prepareUrl(window.location.href)}${welcomeParam}`,
      socialshoppe: '1',
      artistID,
      promoCode: '',
      lookid: lookFromLastItemWasAdded,
    };

    if (salon?.businessId || artist?.connected_location?.businessId) {
      queryParams.SalonID = salon.businessId || artist?.connected_location?.businessId;
    }

    if (!isSalonPage && !isLookSalonPage && !isArtistPage) {
      const baseUrl = takeWhile(prepareUrl(window.location.href), (char) => char !== '/').join('');
      queryParams.redirect = `${baseUrl}/a/${artist.vanity_url}${welcomeParam}`;
    }

    if (isLookSalonPage) {
      const baseUrl = takeWhile(prepareUrl(window.location.href), (char) => char !== '/').join('');
      queryParams.redirect = `${baseUrl}/salon/${salon.vanity_url}${welcomeParam}`;
    }

    if ((isArtistPage || isLookFromArtistPage) && promoCode) {
      queryParams.promoCode = promoCode;
    }

    queryParams.SKU_BASE_ID = products.length ? products.map(getProductQuery).join(',') : '';

    dispatch(basketAction.checkout({ basket, source }));
    dispatch(basketAction.clearBasket());

    redirectUrl = `${endpoint}?${objectToQueryParams(removeEmpty(queryParams), true).toString()}`;

    // eslint-disable-next-line
    console.log({ redirectUrl });

    addQueryParamToUrl('welcome=true').then(() => window.open(redirectUrl, '_self'));
  };
};

export default useCheckoutFlow;
