import { useQueryParams } from '@elc/common';
import { useSelector } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';

import { locationSelector } from '../store/selectors/location.selectors';
import { artistSelector } from '../store/selectors/artist.selectors';
import { pages } from '../routes/config';

const useLookBackNavigation = (): {
  label: string;
  path: string;
} => {
  const { vanityUrl = '', locationName = '' } = useParams();
  const {
    token = '',
    owner_token: ownerPageToken,
    feedback: addFeedback,
    preview,
  } = useQueryParams();

  const location = useSelector(locationSelector);
  const artist = useSelector(artistSelector);
  const fromSalon = useMatch(pages.PRODUCT_FROM_LOCATION); // returns null when not matched
  const fromArtist = useMatch(pages.PRODUCT); // returns null when not matched
  let path = '/';
  let label = '';

  if (fromSalon) {
    path += `salon/${locationName}`;
    label = location?.name;
  }
  if (fromArtist) {
    path += `a/${vanityUrl}`;
    label = artist?.fullName;
  }
  if (ownerPageToken || (token && artist.profile_page_token)) {
    path += `?token=${ownerPageToken || artist.profile_page_token}`;
  }
  if (addFeedback) {
    const feedSeparator = path.indexOf('?') !== -1 ? '&' : '?';
    path += `${feedSeparator}feedback=true`;
  }
  if (preview) {
    const separator = path.indexOf('?') !== -1 ? '&' : '?';
    path += `${separator}preview=true`;
  }

  return {
    label,
    path,
  };
};

export default useLookBackNavigation;
