/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../utils/rwd';

import { TArtist, TCustomArtist } from '../..';
import { Stylist } from './components/Stylist';
import * as Ui from './OurStylists.styles';
import { NewCarousel } from '../NewCarousel/NewCarousel';

type TStylist = TArtist | TCustomArtist;

type OurStylistsProps = {
  stylists: Array<TStylist>;
};

export const OurStylists = ({ stylists }: OurStylistsProps) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const isSmallerThenLg = useMediaQuery({
    query: `(max-width: ${breakpoints.lg}px)`,
  });

  const onChange = useCallback((value) => {
    setActiveSlide(value);
  }, []);

  const showCarouselNavigation = isSmallerThenLg ? stylists.length > 2 : stylists.length > 12;

  const carouselParams = useMemo(() => {
    const params = {
      slidesPerView: isSmallerThenLg ? 2.3 : 6,
      spaceBetween: isSmallerThenLg ? 10 : 20,
    };

    return params;
  }, [isSmallerThenLg]);

  const renderStylists = useCallback(() => {
    if (showCarouselNavigation) {
      return (
        <Ui.CarouselWrapper>
          <NewCarousel carouselParams={carouselParams}>
            {stylists.map((stylist: TStylist) => (
              <Stylist key={stylist.id} stylist={stylist} />
            ))}
          </NewCarousel>
        </Ui.CarouselWrapper>
      );
    }

    return (
      <Ui.StylistRow>
        {stylists.map((stylist: TStylist) => (
          <Stylist noSlide key={stylist.id} stylist={stylist} />
        ))}
      </Ui.StylistRow>
    );
  }, [isSmallerThenLg]);

  if (stylists.length) {
    return (
      <Ui.Wrapper>
        <Ui.Container>
          <Ui.SectionTitle>Artists</Ui.SectionTitle>
          {renderStylists()}
        </Ui.Container>
      </Ui.Wrapper>
    );
  }

  return null;
};
