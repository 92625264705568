import styled from 'styled-components';

export const BasketAndPromoWrapper = styled.div`
  align-self: baseline;
  justify-self: end;
  display: flex;
  align-items: center;
  justify-items: center;
`;

export const BasketWrapper = styled.div`
  filter: invert(1);
  margin-left: 16px;
`;
