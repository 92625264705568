import { omit } from 'lodash';
import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { locationRelativeHrefSelector } from '../../../../aveda/src/store/selectors/location.selectors';
import { isModalOpenSelector, modalActions, SALON_HEADER_MODAL, TUseCheckoutFlowType } from '../..';

import AvedaLogo from '../../assets/svg/aveda-logo.svg';
import { ReactComponent as BurgerMenu } from '../../assets/svg/burger-menu.svg';
import { LinksModal } from './components/LinksModal/LinksModal';

import * as Ui from './SalonTopbar.styles';
import { Content, LinkType, salonTopbarLinks } from './salonTopbarLinks';
import { BasketAndPromo } from './components/BasketAndPromo/BasketAndPromo';
import { HoverBar } from './components/HoverBar/HoverBar';
import useScrollDistanceCheck from '../../hooks/useScrollDistanceCheck';
import { Logo } from './components/Logo/Logo';

type TopbarProps = {
  children?: React.ReactNode;
  logo?: string;
  onClick: TUseCheckoutFlowType;
  fullWidth?: boolean;
};

type HoverBarData = {
  selectedLinkLabel: string | null;
  content: Content;
};

export const SalonTopbar: FC<TopbarProps> = ({ children: basket, logo, onClick, fullWidth }) => {
  const { t } = useTranslation(['app']);
  const topbarRef = useRef<HTMLDivElement>(null);
  const locationHref = useSelector(locationRelativeHrefSelector);

  const dispatch = useDispatch();
  const isHidden = useScrollDistanceCheck(200);
  const linksSmaller = useMediaQuery({
    query: `(max-width: 1100px)`,
  });
  const linksHidden = useMediaQuery({
    query: `(max-width: 767px)`,
  });
  const isModalOpen = useSelector(isModalOpenSelector(SALON_HEADER_MODAL));
  const [hoverBarData, setHoverBarData] = useState<HoverBarData>({
    selectedLinkLabel: null,
    content: null,
  });

  const handleMouseEnter = (data: LinkType) => {
    setHoverBarData({ selectedLinkLabel: data.label, content: data.content });
  };

  const handleMouseLeave = () => {
    setHoverBarData({
      selectedLinkLabel: null,
      content: null,
    });
  };

  return (
    <Ui.SalonTopbarWrapper onMouseLeave={handleMouseLeave} $hidden={isHidden}>
      <Ui.FullWidthBar $backgroundColor="black">
        <Ui.BarText
          $color="white"
          $fontWeight={700}
          onClick={() =>
            onClick({ path: 'https://www.aveda.com/csr-initiatives', source: 'header' })
          }
        >
          {t('salonTopbar.upperBarText')}
        </Ui.BarText>
      </Ui.FullWidthBar>
      <Ui.SalonTopbarContainer $fullWidth={!!fullWidth}>
        {/* @ts-ignore */}
        <Ui.SalonTopbar $linksHidden={linksHidden} ref={topbarRef}>
          <Ui.LogosWrapper $fullWidth={!linksHidden}>
            {/* logo or placeholder for layout */}
            {logo ? (
              <Logo locationHref={locationHref} linksHidden={linksHidden} logo={logo} />
            ) : (
              <div />
            )}{' '}
            <Ui.BrandLogo
              $fullWidth={!linksHidden}
              onClick={() => onClick()}
              src={AvedaLogo}
              alt="Brand Logo"
            />
            {!linksHidden && <BasketAndPromo onClick={onClick}>{basket}</BasketAndPromo>}
          </Ui.LogosWrapper>

          <Ui.LinksWrapper visible={!linksHidden}>
            {salonTopbarLinks.map((link) => (
              <Ui.Link
                $color="rgb(18, 14, 2)"
                $isSmall={linksSmaller}
                key={link.label}
                onClick={() => onClick(omit(link, ['label']))}
                onMouseEnter={() => handleMouseEnter(link)}
                className={`${link.label === hoverBarData.selectedLinkLabel ? 'hoveredOn' : ''}`}
              >
                {link.label}
              </Ui.Link>
            ))}
          </Ui.LinksWrapper>

          <Ui.ButtonsWrapper>
            {linksHidden && (
              <>
                <BasketAndPromo onClick={onClick}>{basket}</BasketAndPromo>
                <Ui.BurgerMenu
                  role="button"
                  onClick={() => {
                    dispatch(modalActions.openModal(SALON_HEADER_MODAL));
                  }}
                >
                  <BurgerMenu />
                </Ui.BurgerMenu>
              </>
            )}
          </Ui.ButtonsWrapper>
        </Ui.SalonTopbar>
        <LinksModal isOpen={isModalOpen} handleCheckout={onClick} />
      </Ui.SalonTopbarContainer>
      {hoverBarData.content?.length && (
        <HoverBar
          content={hoverBarData.content}
          linksSmaller={linksSmaller}
          closeHoverBar={handleMouseLeave}
        />
      )}
    </Ui.SalonTopbarWrapper>
  );
};
