import { media } from '@elc/common';
import React, { FC } from 'react';
import styled from 'styled-components';

const CardDiv = styled.div`
  width: 100%;
  margin: 20px 0px;
  background: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;

  ${media.md`
    margin: 0px;
    width: 75%;
  `}

  ${media.xl`
    margin: 0px;
    width: 50%;
  `}
`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;

  ${media.md`
    font-size: 20px;
  `}
`;

const Text = styled.p`
  text-align: center;

  ${media.md`
    font-size: 20px;
  `}
`;

export const Card: FC<TCard> = ({ title, content, className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CardDiv className={className}>
    <Title>{title}</Title>
    <Text>{content}</Text>
  </CardDiv>
);
