import { pages } from '../routes/config';

const links = [
  {
    labelPath: 'app:pagesNames.about',
    href: pages.ABOUT,
    external: false,
  },
  {
    labelPath: 'app:pagesNames.policy',
    href: 'https://www.aveda.com/customer-service-privacy',
    external: true,
  },
  {
    labelPath: 'app:pagesNames.terms',
    href: 'https://www.aveda.com/customer-service-terms',
    external: true,
  },
  {
    labelPath: 'app:pagesNames.faq',
    href: pages.FAQ,
    external: false,
  },
];

export default links;
