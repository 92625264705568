import React from 'react';
import { TLook } from '../../typings';
import { NewCarousel } from '../NewCarousel/NewCarousel';
import { FavouriteProductSlide } from './components/FavouriteProductSlide';
import * as Ui from './FavouriteProducts.styles';

export type FavouriteProductsType = {
  looks: Array<TLook>;
  locationVanityUrl: string;
  custom_title?: string;
};

export const FavouriteProducts = ({
  looks,
  locationVanityUrl,
  custom_title,
}: FavouriteProductsType) => (
  <Ui.Wrapper>
    <Ui.Container>
      <Ui.SectionTitle>{custom_title || 'Favorite Products'}</Ui.SectionTitle>
      <Ui.CarouselWrapper>
        <NewCarousel>
          {looks.map((look: TLook) => (
            <FavouriteProductSlide
              key={look.short_id}
              look={look}
              locationVanityUrl={locationVanityUrl}
            />
          ))}
        </NewCarousel>
      </Ui.CarouselWrapper>
    </Ui.Container>
  </Ui.Wrapper>
);
