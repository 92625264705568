import styled from 'styled-components';
import { media } from '../../utils/rwd';
import { linkWithoutStyling } from '../../utils/styled-components/mixins';
import { ExternalLink as Link } from '../ExternalLink/ExternalLink';
import { HorizontalSocials } from '../Socials/HorizontalSocials/HorizontalSocials';

import { Button } from '../Button/Button';
import Ctr from '../Container/Container';

import { MOBILE_PADDING } from '../Container/Container.styles';

export const Wrapper = styled.header.attrs(({ className = '' }) => ({
  className,
}))`
  ${media.lg`
    padding: 68px 0 28px 0;
 `}
`;

export const Container = styled(Ctr)`
  ${media.lg.down`
    padding: 0;
  `}
`;

export const Row = styled(Ctr.Row)`
  flex-direction: column;

  ${media.lg`
    flex-direction: row;
  `}
`;

export const ImageCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  ${media.lg`
    width:50%;
  `}
`;

export const Image = styled.img`
  width: 100%;
  max-height: 40vw;
  object-fit: cover;
`;

export const ContentColumn = styled.div`
  text-align: center;
  padding-bottom: 38px;
  padding-left: ${MOBILE_PADDING}px;
  padding-right: ${MOBILE_PADDING}px;

  ${media.lg`
    text-align: left;
    width:50%;
    padding-bottom: 0;
    padding-left: ${(1 / 12) * 100}%;
  `}
`;

export const SalonName = styled.h1.attrs(({ className = '' }) => ({
  className,
}))`
  margin: 0;
  font-size: 22px;
  font-weight: 700;
`;

export const SalonAddress = styled.p`
  text-transform: capitalize;
`;

export const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  ${media.lg`
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
  `}
`;

export const Classification = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
`;

export const ShowMoreContactsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Divider = styled.div`
  width: 95%;
  height: 2px;
  opacity: 0.08;
  background: #000000;
`;

export const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 15px 0 18px 0;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }

  ${media.lg`
    margin: 20px 0;
  `}
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 20px;
  justify-content: center;

  a {
    ${linkWithoutStyling}
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
  ${media.lg`
    justify-content: flex-start;
  `}
`;

export const ContactIcon = styled.div`
  height: 100%;
  cursor: pointer;
  width: 50px;
  text-align: center;
`;

export const ExternalLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MailLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconCaption = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
`;

export const Socials = styled(HorizontalSocials)`
  justify-content: center;
  gap: 40px;
  margin-bottom: 0;
  ${media.lg`
    justify-content: flex-start;
  `}
`;

export const RelatedSalonsButton = styled(Button)`
  // font-size: 14px;
  // line-height: 18px;
  // letter-spacing: 0.02em;
  // color: inherit;

  ${media.lg.down`
    display: none;
  `}
`;

export const RelatedSalonsLink = styled.button.attrs((attrs) => ({
  ...attrs,
  className: 'btn btn-link link-dark',
}))`
  ${media.lg`
    display: none;
  `}
`;

export const Bio = styled.div`
  margin-top: 20px;
`;
