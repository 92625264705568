import React, { FC } from 'react';
import {
  basketSubtotalSelector,
  Button,
  formatPrice,
  TCartItem,
  useImageLazyLoading,
  isBasketPendingSelector,
} from '@elc/common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Item } from './item';
import BasketSpinner from './basketSpinner';

import * as Ui from './basket.styles';

export const Content: FC<{
  basket: Array<TCartItem>;
  onCheckout: () => void;
}> = ({ basket, onCheckout }) => {
  const basketSubtotal = useSelector(basketSubtotalSelector);
  const isBasketPending = useSelector(isBasketPendingSelector);
  const { t } = useTranslation(['app']);
  useImageLazyLoading('basket-item-image', !!basket.length);

  return (
    <Ui.BasketContent>
      {/* eslint-disable-next-line no-nested-ternary */}
      {basket.length > 0 ? (
        basket.map((item: TCartItem) => <Item key={item.sku} item={item} />)
      ) : isBasketPending ? (
        <Ui.EmptyBasketWrapper className="d-flex flex-column justify-content-center align-items-center">
          <BasketSpinner />
        </Ui.EmptyBasketWrapper>
      ) : (
        <Ui.EmptyBasketWrapper className="d-flex flex-column justify-content-center align-items-center">
          <div>
            <h3>{t('basket.empty')}</h3>
            <p style={{ color: '#BC3C3C' }}>{t('basket.emptyDesc')}</p>
          </div>
        </Ui.EmptyBasketWrapper>
      )}

      {!!basket.length && (
        <Ui.BasketButtonContainer>
          <div className="d-flex justify-content-between">
            <h5>{t('basket.subtotal')}</h5>
            <h5>{basket.length ? formatPrice(basketSubtotal, basket[0].currency) : '-'}</h5>
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: t('basket.checkoutText') }} />
          <Button disabled={!basket.length} text={t('basket.proceed')} onClick={onCheckout} />
        </Ui.BasketButtonContainer>
      )}
    </Ui.BasketContent>
  );
};
