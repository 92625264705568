import React from 'react';
import * as Ui from '../ProductList.styles';
import { ProductCardSkeleton } from '../../ProductCard/skeleton/ProductCard.skeleton';
import { ProductListArrow } from '../ProductListArrow';

export const ProductListSkeleton = () => (
  <Ui.List
    withArrows
    autoScroll
    customArrows={{
      left: <ProductListArrow direction="left" />,
      right: <ProductListArrow direction="right" />,
    }}
  >
    {Array.from(Array(4).keys()).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className="product-box" key={index} data-index={index}>
        <ProductCardSkeleton />
      </div>
    ))}
  </Ui.List>
);
