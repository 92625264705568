import styled from 'styled-components';
import { media } from '@elc/common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 36px 0px;
`;

export const PageWrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${media.lg`
    gap:40px;
  `}
`;
