import React, { FC, useEffect } from 'react';
import getOrFp from 'lodash/fp/getOr';
import {
  StatusWrapper,
  CenteredCard,
  useQueryParams,
  analyticsProductClick,
  analyticsProductImpression,
  analyticsSetPageDataLook,
  analyticsSetPageTypeCategory,
  useScrollToTop,
  useAddProductToCart,
  BackNavigation,
  WelcomePopup,
  AvedaLookProductsList,
} from '@elc/common';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchBundle } from '../../store/actions/bundle.actions';
import { useGlobalState, useThunkDispatch, GlobalState } from '../../store';
import { Metatags } from '../../components/Metatags';
import { useStoreLayoutContext } from '../../hooks/useStoreLayoutContext';
import { increaseEntriesCounter } from '../../utils/increaseEntriesCounter';

import * as Ui from './Look.styles';
import { bundleAction } from '../../store/slices/bundle.slice';
import { useGetLookOwner } from '../../hooks/useGetLookOwner';
import { fetchLocation } from '../../store/actions/location.actions';
import { useLookBackNavigation } from '../../hooks/useLookBackNavigation';

type LookRouteParams = { shortId: string; artistName?: string; locationName?: string };

const LookScreen: FC = () => {
  const { shortId = '', artistName, locationName } = useParams<LookRouteParams>();
  const { token = '', owner_token: ownerPageToken, preview } = useQueryParams();

  const lookToken = token || ownerPageToken;
  const dispatch = useThunkDispatch();

  const { status, item: look } = useGlobalState(getOrFp({} as GlobalState['bundle'], 'bundle'));
  const { owner: artist } = look;
  const addProductToCart = useAddProductToCart();

  const { t } = useTranslation(['app', 'metatags']);
  const { setLogo, showAvedaTopBar } = useStoreLayoutContext();

  const backNavigationProps = useLookBackNavigation();
  useScrollToTop();

  useEffect(() => {
    dispatch(fetchBundle({ shortId, token: lookToken }));
    analyticsSetPageDataLook(shortId);

    return () => {
      dispatch(bundleAction.resetBundle());
    };
  }, []);

  useEffect(() => {
    if (locationName) {
      dispatch(fetchLocation(locationName));
    }
  }, [locationName]);

  useGetLookOwner(look.owner.vanity_url, ownerPageToken);

  useEffect(() => {
    if (artist.is_brand_owner) {
      if (artist?.locations?.length) {
        setLogo(artist.locations[0].logo);
      }
    } else {
      setLogo('');
    }
    if (!preview && look._id) {
      // eslint-disable-next-line no-underscore-dangle
      increaseEntriesCounter(look._id, 'LOOK');
    }
  }, [look._id]);

  // to do this logic should be extracted

  useEffect(() => {
    if (look.products.length) {
      analyticsProductImpression(look);
    }
  }, [look.products]);

  const lookBelongsToArist =
    artistName && artist.vanity_url
      ? artist.vanity_url === artistName || artist.full_name === artistName
      : false;

  // const artistLocations = new Set(artist?.locations?.map((location) => location.vanity_url));
  // const lookCanBeInLocation = locationName ? artistLocations.has(locationName) : false;
  const hasPrefix = artistName || locationName;

  // to do: remove location from condition when you will find realtion between look and location
  const lookCanBeInPrefix = hasPrefix && look._id ? lookBelongsToArist || locationName : true; // if there is no prefix it means that it's old link so if look was found by id then it should be shown

  const metaDescription = t('metatags:lookDescription', {
    lookName: look.name,
    ownerName: look.owner.full_name,
    lookDescription: look.message,
  });

  const productsSubSkusProductCodes = look.products
    .map((product) => product.sub_skus.map((sub_sku) => sub_sku.product_code).join(', '))
    .join(', ');
  const keywords = `${t('metatags:keywords')}, ${look.short_id}${
    productsSubSkusProductCodes ? `, ${productsSubSkusProductCodes}` : ''
  }`;

  return (
    <StatusWrapper
      status={lookCanBeInPrefix ? status : 404}
      componentsForStatus={{
        403: (
          <CenteredCard
            title={t('app:errors.accessDenied')}
            content={t('app:errors.tokenNeeded')}
          />
        ),
        400: (
          <CenteredCard
            title={t('app:errors.accessDenied')}
            content={t('app:errors.tokenExpired')}
          />
        ),
      }}
      defaultNotFound={
        <CenteredCard title={t('app:errors.notFound')} content={t('app:errors.lookNotFound')} />
      }
    >
      <div style={{ background: '#fff' }}>
        <WelcomePopup />

        <Metatags
          type="bundle"
          model={look}
          title={preview ? `A-Commerce | Preview Look` : `${look.name} | ${artist.full_name}`}
          description={metaDescription}
          image={look.pictures[0]}
          keywords={keywords}
          noindex={!!look.from_store_template}
        />
        <Ui.LookHeaderWrapper avedaTopBarVisible={showAvedaTopBar}>
          <BackNavigation {...backNavigationProps} />
        </Ui.LookHeaderWrapper>
        <AvedaLookProductsList
          onModalClose={analyticsSetPageTypeCategory}
          onProductDetailsClick={analyticsProductClick}
          onAddToCart={addProductToCart(look.short_id)}
          look={look}
        />
      </div>
    </StatusWrapper>
  );
};

export default LookScreen;
