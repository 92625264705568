import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { media } from '@elc/common';
/* eslint-disable */

const BasketWrapper = styled.a<{ colorRevers: boolean }>`
  position: relative;
  color: white;
  cursor: pointer;
  &:hover {
    color: white;
  }

  ${({ colorRevers }) =>
    colorRevers &&
    `
    path {
      fill : #fff;
    }
  
  `}
`;

const EmptyBasketWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  gap: 6%;
`;

const EmptyBasketImageWrapper = styled.div`
  width: 100%;

  > svg {
    width: 60%;
    height: auto;
  }
`;

const BasketQuantity = styled.p<{ colorRevers: boolean }>`
  position: absolute;
  top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ colorRevers }) => (colorRevers ? 'white' : 'black')};
  width: 100%;
  font-size: 12px;
  line-height: 15px;
`;

const StyledPopup = styled(Popup)<{ popupPostionTop: number; arrowPosition: number }>`
  &-content {
    background: #f1f1f1;
    position: fixed !important;
    right: 0;
    left: unset !important;
    z-index: 9999 !important;
    transition: ${({ theme }) => theme.transitionTime};
    width: 100%;
    top: 0;
    height: 100vh;
    ${(media as any).sm`
    width: 376px;
    top: ${({ popupPostionTop }) => `${popupPostionTop}px !important`};
    height: ${({ popupPostionTop }) => `calc(100vh - ${popupPostionTop}px)`};
    // :after {
    //   content: '';
    //   position: absolute;
    //   right:  ${({ arrowPosition }) => `${arrowPosition}px`};
    //   top: -10px;
    //   width: 0;
    //   height: 0;
    //   border-left: 10px solid transparent;
    //   border-right: 10px solid transparent;
    //   border-bottom: 10px solid #f1f1f1;
    //   clear: both;
    // }

  `}
  }
`;

const BasketHeader = styled.div<{ alignEnd: boolean }>`
  display: flex;
  justify-content: ${({ alignEnd }) => (alignEnd ? 'flex-end' : 'space-between')};
  padding: 16px;
  font-size: 24px;
  text-transform: uppercase;
  align-items: center;
  h5 {
    margin: 0;
    font-weight: bold;
  }
`;

const BasketContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  padding: 0 18px 18px 18px;
  height: calc(100vh - 234px);
  ${(media as any).sm`
  height: calc(100% - 260px);
  padding: 0 18px 0px 18px;


  `}
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .emptyBasket__text {
    align-self: center;
    margin-top: 40%;
  }
`;

const BasketItemWrapper = styled.div`
  display: flex;
  position: relative;
  background: white;
  padding: 10px 20px 10px 10px;
`;

const BasketItemContent = styled.div`
  color: #000000;
  font-size: 12px;
  flex: 1;
  padding-left: 6px;
  .name {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 14px;
    margin: 0;
    max-width: 95%;
  }

  .description {
    line-height: 15px;

    margin: 6px 0;
  }

  .price {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 17px;
    margin: 0;
    font-size: 14px;
  }
`;

const BasketItemImage = styled.div`
  width: 120px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BasketButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  margin: 0 auto;
  padding: 24px 48px;
  background: #f1f1f1;
  z-index: 1;
  ${(media as any).sm.down`
    position: fixed;
    bottom: 0;
    padding: 18px;
  `}
  h5 {
    font-weight: bold;
    text-transform: uppercase;
  }
  p {
    font-size: 12px;
  }
`;

const CloseIcon = styled(MdClose).attrs({
  size: 14,
})`
  margin: 10px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const QuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  margin: 12px 0 0 0;
  align-items: center;
`;
const QuantityButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
`;
const Quantity = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

// How to handle long names of colors that doesn't fit one line?
export const ColorName = styled.h4.attrs(({ className = '' }) => ({
  className,
}))<{ color: string; theme: any }>`
  font-size: ${({ theme }) => theme.productCard.colorName.fontSize};
  font-weight: 500;
  line-height: ${({ theme }) => theme.productCard.colorName.lineHeight};
  color: ${({ color }) => color};
  margin: 0;
  margin-left: 4px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
`;

const Size = styled.p`
  padding: 5px 10px;
  background: black;
  color: white;
`;

export {
  BasketWrapper,
  EmptyBasketWrapper,
  EmptyBasketImageWrapper,
  BasketItemImage,
  BasketItemWrapper,
  BasketItemContent,
  BasketQuantity,
  StyledPopup,
  BasketButtonContainer,
  BasketContent,
  BasketHeader,
  CloseIcon,
  QuantityWrapper,
  QuantityButton,
  Quantity,
  Size,
};
