import {
  CenteredCard,
  ComponentLoader,
  Footer,
  LookSkeleton,
  SalonHeader,
  SalonHeaderSkeleton,
  SalonTopbar,
  StatusWrapper,
  WelcomePopup,
  analyticsSetPageDataSalon,
  createLookLink,
  useAddProductToCart,
  useQueryParams,
  useScrollToTop,
} from '@elc/common';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AvedaLogoWhite from '../assets/aveda-logo-white.png';
import { Metatags } from '../components/Metatags';
import { Basket, BasketIcon } from '../components/basket/basket';
import footerLinks from '../constants/footerLinks';
import { useAvedaRedirect } from '../hooks/useAvedaRedirect';
import { useGlobalState, useThunkDispatch } from '../store';
import { fetchLocation } from '../store/actions/location.actions';
import { fetchLocationPage } from '../store/actions/locationPage.actions';
import { bundleAction } from '../store/slices/bundle.slice';
import { increaseEntriesCounter } from '../utils/increaseEntriesCounter';
import { renderLocationPageElement, getLocationAddress } from '../utils/locationUtils';
import * as Ui from './Location.styles';

type LocationRouteParams = { vanity_url: string };

const Location: React.FC = () => {
  const { vanity_url = '' } = useParams<LocationRouteParams>();
  const redirectToAveda = useAvedaRedirect();
  const navigate = useNavigate();
  const { token = '', preview, feedback: addFeedback } = useQueryParams();
  const dispatch = useThunkDispatch();
  const { status, item: location } = useGlobalState((state) => state.location);
  const [searchParams] = useSearchParams();

  let pageTitle = location.name;
  if (searchParams.get('previewchanges')) {
    pageTitle = 'A-Commerce | Web Shoppe Preview Changes';
  }
  if (searchParams.get('preview')) {
    pageTitle = 'A-Commerce | Web Shoppe View Page';
  }

  const {
    loading: pageLoading,
    item: { page: locationPageElements },
  } = useGlobalState((state) => state.locationPage);
  const addProductToCart = useAddProductToCart();
  const { t } = useTranslation(['metatags']);

  useScrollToTop();

  useEffect(() => {
    analyticsSetPageDataSalon(vanity_url);
  }, [vanity_url]);

  useEffect(() => {
    dispatch(fetchLocation(vanity_url));
  }, [vanity_url]);

  const isCorrectLocationFetched = [location.vanity_url, location.old_vanity_url].includes(
    vanity_url,
  );

  useEffect(() => {
    if (isCorrectLocationFetched) {
      const locationPageModules =
        searchParams.get('previewchanges')?.toLowerCase() === 'true'
          ? location.page_modules
          : location.page_modules.filter((module) => module.is_published);

      dispatch(
        fetchLocationPage({
          locationVanity: vanity_url,
          pageModules: locationPageModules,
          locationParticipants: location.participants,
        }),
      );
    }
  }, [location.page_modules, isCorrectLocationFetched]);

  useEffect(() => {
    if (isCorrectLocationFetched) {
      increaseEntriesCounter(location.uid, 'SALON');
    }
  }, [location.uid]);

  const metaDescription = t('salonDescription', {
    salonName: location.name,
    salonLocation: getLocationAddress(location),
  });

  const keywords = `${t('keywords')}, ${location.businessId}, ${location.vanity_url}, ${
    location.address.post_town
  }, ${location.address.street_name}`;

  const handleShopNowClick = (look) => {
    dispatch(bundleAction.setPendingStatusForLook());
    navigate(
      createLookLink(look, preview === 'true', !!addFeedback, token, {
        locationName: location.vanity_url,
      }),
      {
        state: {
          from: 'salon',
        },
      },
    );
  };

  return (
    <>
      <Basket />
      <WelcomePopup />
      <SalonTopbar onClick={redirectToAveda} logo={location.logo}>
        <BasketIcon />
      </SalonTopbar>
      <StatusWrapper
        status={status}
        defaultNotFound={
          <CenteredCard
            title={t('app:errors.notFound')}
            content={t('app:errors.locationNotFound')}
          />
        }
      >
        <Ui.PageWrapper>
          <Metatags
            title={pageTitle}
            image={location.cover_photo || location.logo}
            description={metaDescription}
            keywords={keywords}
          />
          <ComponentLoader isReady={isCorrectLocationFetched} skeleton={<SalonHeaderSkeleton />}>
            <SalonHeader salon={location} className="salonHeader" />
          </ComponentLoader>

          <ComponentLoader
            isReady={pageLoading === 'fullfield' || location.page_modules.length === 0}
            skeleton={
              <>
                <LookSkeleton />
                <LookSkeleton />
              </>
            }
          >
            {locationPageElements.map((c) =>
              renderLocationPageElement(
                c,
                addProductToCart,
                handleShopNowClick,
                location.vanity_url,
                redirectToAveda,
              ),
            )}
          </ComponentLoader>
          <Footer logo={AvedaLogoWhite} links={footerLinks} showDisclaimer />
        </Ui.PageWrapper>
      </StatusWrapper>
    </>
  );
};

export default Location;
