import React, { FC, useEffect, useState } from 'react';

import * as Ui from './Logo.styles';
import { isImageTransparentAndWithWhiteEdges } from '../../../../utils/isImageTransparentAndWithWhiteEdges';

type LogoProps = {
  locationHref: string;
  linksHidden: boolean;
  logo: string;
};

export const Logo: FC<LogoProps> = ({ locationHref, linksHidden, logo }) => {
  const [shouldAddBackground, setShouldAddBackground] = useState(false);

  useEffect(() => {
    const foo = async () => {
      try {
        setShouldAddBackground(await isImageTransparentAndWithWhiteEdges(logo));
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    foo();
  }, [logo]);

  return (
    <a href={locationHref}>
      <Ui.Logo $fullWidth={!linksHidden} $shouldAddBackground={shouldAddBackground} src={logo} />
    </a>
  );
};
