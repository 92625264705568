import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { pages } from '../../../aveda/src/routes/config';
import { basketQuantitySelector, basketAction } from '../store';
import { TSelectedSku } from '../typings';
import { saveAddToCartEntry } from '../api';

export const useAddProductToCart = () => {
  const dispatch = useDispatch();
  const basketQuantity = useSelector(basketQuantitySelector);
  const isLookPage = useMatch('/look/:shortId') !== null;
  const isProductPage = useMatch('a/:vanityUrl/p/:shortId') !== null;
  const isLookFromArtist = useMatch('/a/:artistName/look/:shortId') !== null;

  const artistId = useSelector(
    // @ts-expect-error
    (state) => state?.artist?.item.publishing_id || state?.artist?.item.artist_id || '',
  );

  const pageMatch = Object.keys(pages).find((key) => useMatch(pages[key]) !== null);

  return useCallback(
    (lookShortId: string) => (product_skus: TSelectedSku | TSelectedSku[]) => {
      dispatch(
        basketAction.setArtistId(isLookPage || isProductPage || isLookFromArtist ? artistId : ''),
      );

      const addToBasket = (variant: TSelectedSku) => {
        dispatch(
          basketAction.addToBasket({
            ...variant,

            bundleId: lookShortId,
            quantity: 1,
          }),
        );
      };
      product_skus = ([] as TSelectedSku[]).concat(product_skus);
      product_skus.forEach((variant) => {
        addToBasket(variant);
      });
      const emptyBasket = basketQuantity === 0;
      saveAddToCartEntry(
        pageMatch || '',
        lookShortId,
        product_skus.map((v) => v.sku),
        emptyBasket,
      );
    },
    [basketQuantity, isLookFromArtist, isProductPage, isLookPage, artistId, dispatch],
  );
};
