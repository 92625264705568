import React, { FC } from 'react';
import { Footer } from '@elc/common';
import { Outlet } from 'react-router-dom';

import AvedaLogo from '../../assets/logo.svg';
import AvedaLogoWhite from '../../assets/aveda-logo-white.png';
import { Page, PageBackground, StyledHeader, Wrapper } from './Layout.styles';
import footerLinks from '../../constants/footerLinks';

const Layout: FC = () => (
  <Wrapper>
    <StyledHeader brandLogoUrl={AvedaLogo} />

    <PageBackground>
      <Page>
        <Outlet />
      </Page>
    </PageBackground>
    <Footer logo={AvedaLogoWhite} links={footerLinks} showDisclaimer={false} />
  </Wrapper>
);

export default Layout;
