import { useEffect } from 'react';

export const DelayRedirect = ({ url = 'https://www.aveda.com' }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace(url);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  return null;
};
