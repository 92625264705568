import React from 'react';
import { useNavigate } from 'react-router-dom';
import { E2EWrapper } from '../../E2EWrapper/E2EWrapper';
import { TLook } from '../../..';

import * as Ui from './FavouriteProductSlide.styles';

export type FavouriteProductSlideProps = {
  look: TLook;
  locationVanityUrl: string;
};

export const FavouriteProductSlide = ({
  look,
  locationVanityUrl,
  ...styles
}: FavouriteProductSlideProps) => {
  const navigate = useNavigate();

  const getSingleProductImage = (singleProductLook: TLook) => {
    const imageUrl = singleProductLook?.products?.[0]?.primary_sku?.images?.[0];
    return imageUrl || singleProductLook.pictures[0];
  };

  const getSingleProductName = (singleProductLook: TLook) => {
    const name = singleProductLook?.products?.[0]?.name;
    return name || singleProductLook.name;
  };

  const redirectToLook = () => {
    navigate(`a/${look.owner.vanity_url}/p/${look.short_id}`, {
      state: {
        from: 'salon',
      },
    });
  };
  return (
    <E2EWrapper id={`salonLook-${look.name}`} {...styles}>
      <Ui.Slide role="button" onClick={redirectToLook}>
        <Ui.CoverPhotoWrapper>
          <Ui.Image src={getSingleProductImage(look)} />
        </Ui.CoverPhotoWrapper>
        <Ui.Name
          dangerouslySetInnerHTML={{
            __html: getSingleProductName(look),
          }}
        />
      </Ui.Slide>
    </E2EWrapper>
  );
};
