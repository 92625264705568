import React from 'react';
import { useTranslation } from 'react-i18next';
import image from '../assets/img/about.png';
import { Card } from '../components/card/Card';
import { CardWrapper } from '../components/wrappers/wrapper.styles';

const About: React.FC = () => {
  const { t } = useTranslation(['app']);

  return (
    <CardWrapper>
      <img
        alt="banner"
        src={image}
        style={{ width: '100%', aspectRatio: '16/6', objectFit: 'cover' }}
      />
      <Card title={t('about.title')} content={t('about.content')} className="my-5" />
    </CardWrapper>
  );
};

export default About;
