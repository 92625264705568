import React, { FC } from 'react';
import { omit } from 'lodash';
import { useDispatch } from 'react-redux';

import { modalActions, TUseCheckoutFlowType } from '../../../..';
import { salonTopbarLinks } from '../../salonTopbarLinks';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrow-right-black.svg';

import * as Ui from './LinksModal.styles';

type ShareModalProps = {
  isOpen: boolean;
  handleCheckout: TUseCheckoutFlowType;
};

export const LinksModal: FC<ShareModalProps> = ({ isOpen, handleCheckout }) => {
  const dispatch = useDispatch();
  return (
    <Ui.Overlay isOpen={isOpen}>
      <Ui.Modal
        isOpen={isOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Ui.Header>
          <Ui.CloseButton onClick={() => dispatch(modalActions.closeModal())} />
        </Ui.Header>
        <Ui.LinkList>
          {salonTopbarLinks.map((link) => (
            <Ui.Link key={link.label} onClick={() => handleCheckout(omit(link, ['label']))}>
              {link.label}
              <ArrowRight />
            </Ui.Link>
          ))}
        </Ui.LinkList>
      </Ui.Modal>
    </Ui.Overlay>
  );
};
