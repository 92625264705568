import React, { FC, useState, useCallback, useRef } from 'react';
import Carousel, { slidesToShowPlugin, Dots, arrowsPlugin } from '@brainhubeu/react-carousel';

import { ProductCard, SAddToCart, TLook } from '../..';
import { useImageLazyLoading } from '../../hooks/useImageLazyLoad';
import { useProductSelection } from './hooks/useProductSelection';
import { EInventory, SProductDetailsClick } from '../../typings';
import { LeftCarouselArrow, RightCarouselArrow } from '../CarouselArrows/CarouselArrows';
import * as Ui from './AvedaLookProductsList.styles';
import { HtmlTruncatedText } from '../TruncateText/HtmlTruncatedText';
import { Container } from '../Container/Container.styles';

const ProductCardOverlay: FC<{
  // isChecked: boolean;
  // onClick: () => void;
}> = ({ children }) => (
  <Ui.ProductCardWrapper>
    {/* <Ui.ClickingArea onClick={onClick}>
      <Ui.Checkbox isChecked={isChecked}>{isChecked && <CheckMark />}</Ui.Checkbox>
    </Ui.ClickingArea> */}

    {children}
  </Ui.ProductCardWrapper>
);

export const AvedaLookProductsList = ({
  look,
  onAddToCart,
  onProductDetailsClick,
  onModalClose,
}: {
  look: TLook;
  onAddToCart: SAddToCart;
  onProductDetailsClick?: SProductDetailsClick;
  onModalClose?: () => void;
}) => {
  useImageLazyLoading('product-image', !!look?.products?.length);

  const { totalPrice, numberOfSelectedProducts, onVariantClick, handleAddMultipleProductsToCart } =
    useProductSelection(look, onAddToCart);

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const carouselDotsVisible = look?.pictures.length > 1;

  const onChange = useCallback((value) => {
    setActiveSlide(value);
  }, []);

  const renderLookCoverPhoto = () => {
    if (!look.pictures.length) return null;

    if (look.pictures.length === 1) {
      return (
        <Ui.CoverPhotoWrapper>
          {!!look.pictures.length && <Ui.CoverPhoto src={look.pictures[0]} />}
        </Ui.CoverPhotoWrapper>
      );
    }

    return (
      <Ui.CarouselWrapper>
        <Carousel
          value={activeSlide}
          onChange={onChange}
          plugins={[
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <Ui.CarouselArrowWrapper arrow="left">
                    <LeftCarouselArrow />
                  </Ui.CarouselArrowWrapper>
                ),
                arrowLeftDisabled: (
                  <Ui.CarouselArrowWrapper arrow="left">
                    <LeftCarouselArrow disabled />
                  </Ui.CarouselArrowWrapper>
                ),
                arrowRight: (
                  <Ui.CarouselArrowWrapper arrow="right">
                    <RightCarouselArrow />
                  </Ui.CarouselArrowWrapper>
                ),
                arrowRightDisabled: (
                  <Ui.CarouselArrowWrapper arrow="right">
                    <RightCarouselArrow disabled />
                  </Ui.CarouselArrowWrapper>
                ),
                addArrowClickHandler: true,
              },
            },
          ]}
        >
          {!!look.pictures.length &&
            look.pictures.map((picture) => (
              <Ui.Slide role="button" key={picture}>
                <Ui.CoverPhotoWrapper>
                  <Ui.Image src={picture} />
                </Ui.CoverPhotoWrapper>
              </Ui.Slide>
            ))}
        </Carousel>
        <Ui.DotsWrapper>
          <Dots value={activeSlide} onChange={setActiveSlide} number={look?.pictures.length} />
        </Ui.DotsWrapper>
      </Ui.CarouselWrapper>
    );
  };

  return (
    <div className="postion-relative">
      <Ui.ContentContainer
        className="container"
        offsetTop={wrapperRef.current?.offsetTop}
        ref={wrapperRef}
      >
        <Ui.CoverPhotoColumn className="cover-photo-column d-flex flex-column">
          <Ui.ContentWrapper>
            <Ui.ContentHeading>{look.name}</Ui.ContentHeading>
            <Ui.LookDescription>
              <HtmlTruncatedText sentencesCount={1} html={look.message} />
            </Ui.LookDescription>
          </Ui.ContentWrapper>
          {renderLookCoverPhoto()}
        </Ui.CoverPhotoColumn>

        <Ui.ContentColumn noPaddingTop={carouselDotsVisible}>
          <Ui.ProductListWrapper>
            {look.products
              .slice()
              .sort((a, b) => {
                if (
                  a.primary_sku.inventory.toUpperCase() === EInventory.ACTIVE &&
                  b.primary_sku.inventory.toUpperCase() !== EInventory.ACTIVE
                ) {
                  return -1; // Przesuń a przed b
                }
                if (
                  a.primary_sku.inventory.toUpperCase() !== EInventory.ACTIVE &&
                  b.primary_sku.inventory.toUpperCase() === EInventory.ACTIVE
                ) {
                  return 1; // Przesuń b przed a
                }
                return 0; // Sortuj normalnie
              })
              .map((product, idx) => (
                <ProductCardOverlay
                  // isChecked={isProductSelected(product)}
                  // onClick={() => {
                  //   onCheckboxClick(product);
                  // }}
                  key={product.name}
                >
                  <ProductCard
                    onVariantClick={(selectedSku) => {
                      onVariantClick(selectedSku);
                    }}
                    onProductDetailsClick={onProductDetailsClick?.(look.name)?.(idx)}
                    onModalClose={onModalClose}
                    onAddToCart={onAddToCart}
                    product={product}
                    visibleColors={4}
                  />
                </ProductCardOverlay>
              ))}
          </Ui.ProductListWrapper>
        </Ui.ContentColumn>
      </Ui.ContentContainer>
      {!!numberOfSelectedProducts && (
        <Ui.FixedBarWrapper>
          <Container>
            <div className="fix-bar d-flex align-items-center h-100">
              <div className="fix-bar-column">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <h2 className="fix-bar-look-name">{look.name}</h2>
                  <p className="fix-bar-look-desc">{`${numberOfSelectedProducts} Look product${
                    numberOfSelectedProducts > 1 ? 's' : ''
                  } for - ${totalPrice}`}</p>
                </div>
              </div>
              <div className="fix-bar-column">
                <button
                  type="button"
                  onClick={handleAddMultipleProductsToCart}
                  className="fix-bar-button"
                >
                  ADD ALL PRODUCTS TO BAG
                </button>
              </div>
            </div>
          </Container>
        </Ui.FixedBarWrapper>
      )}
    </div>
  );
};

export default AvedaLookProductsList;
