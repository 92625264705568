import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFetchableItem } from '@elc/common';
import * as locationPageActions from '../actions/locationPage.actions';

const initialState: IFetchableItem<ILocationPage> = {
  item: {
    locationVanity: '',
    page: [],
  },
  loading: 'pending',
  status: 200,
};

const locationPageSlice = createSlice({
  name: 'locationPage',
  initialState,
  reducers: {
    setStore: (state, action: PayloadAction<ILocationPage>) => {
      state.item = action.payload;
      state.loading = 'fullfield';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(locationPageActions.fetchLocationPage.fulfilled, (state, { payload }) => {
        state.loading = 'fullfield';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state.item = payload as any;
      })
      .addCase(locationPageActions.fetchLocationPage.pending, (state) => {
        state.loading = 'pending';
      });
  },
});

export const locationPageAction = {
  ...locationPageSlice.actions,
};

export default locationPageSlice;
