import React, { FC } from 'react';
import { Skeleton, SkeletonRectangle } from '../../Skeleton/Skeleton';
import * as Ui from '../brands/MacProductCard/MacProductCard.styles';

export const ProductCardSkeleton: FC = () => (
  <Ui.Wrapper>
    <Ui.StarRatingWrapper>
      <Skeleton width={100} height={15} viewBox="0 0 100 15">
        <SkeletonRectangle width="100" height="15" />
      </Skeleton>
    </Ui.StarRatingWrapper>
    <Skeleton style={{ width: '80%', maxHeight: 140, margin: '0 auto' }}>
      <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
    </Skeleton>

    <Ui.Name>
      <Skeleton width={100} height={15} viewBox="0 0 100 15">
        <SkeletonRectangle width="100" height="15" />
      </Skeleton>
    </Ui.Name>

    <Ui.Description>
      <Skeleton style={{ width: '100%' }} height={25}>
        <SkeletonRectangle style={{ width: '85%' }} height="10" />
        <SkeletonRectangle y="15" style={{ width: '70%' }} height="10" />
      </Skeleton>
    </Ui.Description>

    <div style={{ minHeight: 38 }}>
      <Ui.ColorName color="">
        <Skeleton width={100} height={10} viewBox="0 0 100 10">
          <SkeletonRectangle width="100" height="10" />
        </Skeleton>
      </Ui.ColorName>
    </div>

    <Ui.Price>
      <Skeleton width={50} height={20} viewBox="0 0 50 20">
        <SkeletonRectangle width="50" height="20" />
      </Skeleton>
    </Ui.Price>
    <Skeleton height={58} style={{ width: '100%' }}>
      <SkeletonRectangle y="16" style={{ width: '100%' }} height="42" />
    </Skeleton>
  </Ui.Wrapper>
);
