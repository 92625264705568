import React from 'react';
import { EBrand } from '../../typings/enums/Brands';
import { DelayRedirect } from '../DelayRedirect/DelayRedirect';

type TStatusWrapper = {
  status: number;
  componentsForStatus?: { [status: string]: JSX.Element };
  defaultNotFound: JSX.Element;
  children: JSX.Element;
  brand?: string;
};

export const StatusWrapper = ({
  status,
  componentsForStatus,
  defaultNotFound,
  children,
  brand = EBrand.Aveda,
}: TStatusWrapper) => {
  if (status === 200) {
    return children;
  }

  const redirectUrl =
    brand === EBrand.Aveda ? 'https://www.aveda.com' : 'https://www.maccosmetics.com/';

  return (
    <>
      <DelayRedirect url={redirectUrl} />
      {componentsForStatus?.[status] || defaultNotFound}
    </>
  );
};
