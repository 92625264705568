import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  salonGroupSelector,
  isSalonGroupLoadingSelector,
  fetchSalonGroupAction,
  TSalonGroup,
  getLocationAddress,
  CenteredCard,
} from '@elc/common';

import AvedaLogoBlack from '../../assets/aveda-logo-black.png';
import AvedaLogoWhite from '../../assets/aveda-logo-white.png';

import { ReactComponent as ScissorsIcon } from '../../assets/svg/scissorscomb.svg';
import { Metatags } from '../../components/Metatags';
import * as Ui from './SalonGroupPage.styles';

export const SalonGroupPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const salonGroup = useSelector(salonGroupSelector);
  const isSalonGroupLoading = useSelector(isSalonGroupLoadingSelector);
  const { vanityUrl } = useParams();
  const { t } = useTranslation(['app']);

  type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

  const handleLocationClick = (location: ArrayElement<TSalonGroup['locations']>) => {
    navigate(`/salon/${location.vanity_url}`, {
      state: {
        from: '',
      },
    });
  };

  useEffect(() => {
    dispatch(fetchSalonGroupAction(vanityUrl || ''));
  }, []);

  if (isSalonGroupLoading) {
    return null;
  }

  if (!salonGroup) {
    return (
      <Ui.Background>
        <CenteredCard
          title={t('app:errors.notFound')}
          content={t('app:errors.salonGroupPageNotFound')}
        />
      </Ui.Background>
    );
  }

  return (
    <Ui.Background>
      <Metatags title={salonGroup?.name} image={salonGroup?.logo || AvedaLogoWhite} />
      <Ui.FakeModal>
        {' '}
        <Ui.Header>
          <div className="d-flex align-items-center">
            <Ui.SalonLogo src={salonGroup.logo} />
            <Ui.GroupPageName>{salonGroup.name}</Ui.GroupPageName>
          </div>
          <Ui.HeaderDescription className="mt-2 text-center">
            <b>Shop Aveda: </b>Select your location to support your local salon
          </Ui.HeaderDescription>
        </Ui.Header>
        <Ui.List>
          {salonGroup.locations.map((location) => (
            <Ui.Item key={location.uid} onClick={() => handleLocationClick(location)}>
              <ScissorsIcon />
              <Ui.ContentWrapper>
                <Ui.SalonName>{location.name}</Ui.SalonName>
                <Ui.SalonAddress>{getLocationAddress(location)}</Ui.SalonAddress>
              </Ui.ContentWrapper>
              <Ui.Triangle />
            </Ui.Item>
          ))}
        </Ui.List>
        <Ui.Footer>
          <Ui.AvedaLogo src={AvedaLogoBlack} />
        </Ui.Footer>
      </Ui.FakeModal>
    </Ui.Background>
  );
};
