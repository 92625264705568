const { pathname } = window.location;

const [, artist] = pathname.match(/\/a\/([\w\d]+)/) || [];
const [, look] = pathname.match(/\/look\/([\w\d]+)/) || [];
const [, salon] = pathname.match(/\/salon\/([\w\d]+)/) || [];
const categoryPage = artist || look || salon;

let pageTemplate = 'home';
const pageType = categoryPage ? 'category' : 'home';
const pageName = categoryPage || 'home';

if (artist) {
  pageTemplate = 'artist';
}

if (look) {
  pageTemplate = 'look';
}

if (salon) {
  pageTemplate = 'salon';
}

const dataLayer = [
  {
    country: process.env.COUNTRY || 'US',
    language: process.env.LANGUAGE || 'en',
    currencyCode: process.env.CURRENCY || 'USD',
    brand: 'Aveda',
    brandId: `Aveda ${process.env.REGION || 'US'}`,
    brandLocale: process.env.REGION || 'US',
    environment: process.env.NODE_ENV || 'development',
    pageTemplate,
    pageType,
    pageName,
  },
];

export { dataLayer };
