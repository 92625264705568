import { IFetchableItem, TLook } from '@elc/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as bundleActions from '../actions/bundle.actions';

const initialState: IFetchableItem<TLook> = {
  item: {
    _id: '',
    short_id: '',
    currency: '',
    message: '',
    name: '',
    products: [],
    is_primary: false,
    is_draft: false,
    is_private: false,
    is_visible: false,
    hausmart_type: 'BOTH',
    is_single_product: false,
    from_store_template: null,
    views_count: 0,
    pictures: [],
    created_at: '',
    aspect_ratio: '1:1',
    owner: {
      full_name: '',
      locations: [],
      is_brand_manager: false,
      is_brand_owner: false,
      uid: '',
      id: 0,
      picture: '',
      vanity_url: '',
      profile_page_token: '',
    },
  },
  loading: 'pending',
  status: 200,
};

const bundleSlice = createSlice({
  name: 'bundle',
  initialState,
  reducers: {
    setBundle: (state, action: PayloadAction<TLook>) => {
      state.item = action.payload;
      state.loading = 'fullfield';
    },
    setPendingStatusForLook: (state) => {
      state.loading = 'pending';
    },
    resetBundle: (state) => {
      state.item = initialState.item;
      state.loading = 'pending';
      state.status = 200;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bundleActions.fetchBundle.fulfilled, (state, { payload }) => {
        state.loading = 'fullfield';
        state.item = payload;
        state.status = 200;
      })
      .addCase(bundleActions.fetchBundle.rejected, (state, { payload }) => {
        state.loading = 'rejected';
        state.status = payload as number;
      });
  },
});

export const bundleAction = {
  ...bundleSlice.actions,
};

export default bundleSlice;
