import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStore,
  getPrivateStore,
  IFetchableItem,
  isLookAccessible,
  getArtistLook,
  TLookShort,
} from '@elc/common';

import { TAvedaArtist } from '../../@types/AvedaArtist';
import { bringElementToFront } from '../../utils/artistUtils';

const fetchSingleBundle = async (short_id: string): Promise<TLookShort> => {
  try {
    const axiosResponse = await getArtistLook(short_id);
    const [look] = axiosResponse.data;
    return look;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchArtistLook = createAsyncThunk(
  'artistLooks/fetchSingle',
  async ({
    lookShortId,
    entityType,
  }: {
    lookShortId: string;
    entityType: 'looks' | 'favorites';
  }) => {
    try {
      const look = await fetchSingleBundle(lookShortId);

      return { look, entityType };
    } catch (error) {
      return Promise.reject(error);
    }
  },
  {
    condition: ({ lookShortId, entityType }, { getState }) => {
      const { artist } = getState() as { artist: IFetchableItem<TAvedaArtist> };
      if (entityType === 'looks') {
        // @ts-ignore
        const look = artist.item.looks.find((l) => l?.products && l.short_id === lookShortId);
        if (look) {
          return false;
        }
      }
    },
  },
);

export const setArtistFavorites = createAsyncThunk(
  'artistLooks/setArtistFavorites',
  async () => {},
);

export const fetchArtistFavorites = createAsyncThunk(
  'artistLooks/fetch',
  async (_, { dispatch, getState }) => {
    const {
      artist: {
        item: { stores },
      },
    } = getState() as { artist: IFetchableItem<TAvedaArtist> };

    const favoritesToFetch = stores.filter((i) => i.is_single_product);
    try {
      favoritesToFetch.forEach((fav) =>
        // @ts-ignore
        dispatch(fetchArtistLook({ lookShortId: fav?.short_id, entityType: 'favorites' })),
      );

      if (!favoritesToFetch.length) {
        dispatch(setArtistFavorites());
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  {
    condition: (artistVanity, { getState }) => {
      const { artist } = getState() as { artist: IFetchableItem<TAvedaArtist> };
      if (artist.item.favorites?.length) {
        return false;
      }
    },
  },
);

export const fetchArtist = createAsyncThunk(
  'artist/fetchArtist',
  async (artistVanity: string, { rejectWithValue }) => {
    try {
      const { data: response } = await getStore(artistVanity);

      if (response) {
        response.looks = bringElementToFront(
          response.stores
            .sort((a, b) =>
              new Date(a.created_at).getTime() < new Date(b.created_at).getTime() ? 1 : -1,
            )
            .filter(isLookAccessible),
          'is_primary',
        );
        response.favorites = null;

        return response;
      }
      throw Error();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return rejectWithValue((error as any).response.status);
    }
  },
  {
    condition: (artistVanity, { getState }) => {
      const { artist } = getState() as { artist: IFetchableItem<TAvedaArtist> };
      if (artist.item.vanity_url === artistVanity) {
        return false;
      }
    },
  },
);

export const fetchPrivateArtistProfile = createAsyncThunk(
  'artist/fetchPrivateArtistProfile',
  async (
    {
      vanity_url,
      token,
    }: {
      vanity_url: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: response } = await getPrivateStore(vanity_url, token);

      if (response) {
        response.looks = bringElementToFront(
          response.stores
            .sort((a, b) =>
              new Date(a.created_at).getTime() < new Date(b.created_at).getTime() ? 1 : -1,
            )
            .filter(isLookAccessible),
          'is_primary',
        );
        response.favorites = [];

        return response;
      }
      throw Error();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return rejectWithValue((error as any).response.status);
    }
  },
  {
    condition: ({ vanity_url }, { getState }) => {
      const { artist } = getState() as { artist: IFetchableItem<TAvedaArtist> };
      if (artist.item.vanity_url === vanity_url) {
        return false;
      }
    },
  },
);
