import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFetchableItem, ILocation } from '@elc/common';
import * as locationActions from '../actions/location.actions';

const initialState: IFetchableItem<ILocation> = {
  item: {
    id: 0,
    uid: '',
    businessId: '',
    vanity_url: '',
    old_vanity_url: '',
    name: '',
    logo: '',
    address: {
      id: 0,
      uid: '',
      name: '',
      number_name: '',
      street_name: '',
      locality: '',
      post_town: '',
      post_code: '',
      country: '',
    },
    page_modules: [],
    sap_id: '',
    secondary_logo_id: '',
    secondary_logo: '',
    region: 0,
    owners: [],
    managers: [],
    logo_id: '',
    is_primary: false,
    cover_photo_id: '',
    twitter_handle: null,
    facebook_handle: null,
    pinterest_handle: null,
    instagram_handle: null,
    classification: '',
    phone_primary: '',
    phone_secondary: '',
    cover_photo: '',
    book_email: '',
    book_online_url: '',
    website_url: '',
    toll_free_number: '',
    participants: [],
    assigned_owner_group_pages: [],
    bio: '',
  },
  loading: 'pending',
  status: 200,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setBundle: (state, action: PayloadAction<ILocation>) => {
      state.item = action.payload;
      state.loading = 'fullfield';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(locationActions.fetchLocation.fulfilled, (state, { payload }) => {
        state.loading = 'fullfield';
        state.item = payload;
        state.status = 200;
      })
      .addCase(locationActions.fetchLocation.rejected, (state, { payload }) => {
        state.loading = 'rejected';
        state.status = payload as number;
      });
  },
});

export const locationAction = {
  ...locationSlice.actions,
};

export default locationSlice;
