import styled from 'styled-components';

import { Container, media } from '@elc/common';

export const LookHeaderWrapper = styled(Container)<{ avedaTopBarVisible?: boolean }>`
  position: sticky;
  top: ${({ avedaTopBarVisible }) => (avedaTopBarVisible ? '50px' : '41px')};
  background: #fff;
  z-index: 10;

  ${media.lg`
      box-sizing: border-box;
      position: relative;
      top:0;
  `}

  ${media.xl`
    gap: 75px;
  `}
`;
