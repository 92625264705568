import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { TUseCheckoutFlowType } from '../../../..';

import * as Ui from './BasketAndPromo.styles';
import { ReactComponent as UserIcon } from '../../../../assets/svg/user-icon.svg';
import { ReactComponent as AvedaPlusIcon } from '../../../../assets/svg/aveda-plus.svg';
import { Promo } from '../Promo/Promo';

type BasketAndPromoProps = {
  children?: React.ReactNode;
  onClick: TUseCheckoutFlowType;
};

export const BasketAndPromo: FC<BasketAndPromoProps> = ({ children: basket, onClick }) => {
  const { t } = useTranslation(['app']);
  // with 800px third Promo should fit if necessary
  const isTextHidden = useMediaQuery({
    query: `(max-width: 800px)`,
  });

  return (
    <Ui.BasketAndPromoWrapper>
      <Promo
        onClick={onClick}
        href="https://www.aveda.com/aveda-plus-rewards-program"
        text={t('salonTopbar.joinRewards')}
        isTextHidden={isTextHidden}
        Icon={AvedaPlusIcon}
      />
      <Promo
        onClick={onClick}
        href="https://www.aveda.com/account/signin.tmpl"
        text={t('salonTopbar.signIn')}
        isTextHidden={isTextHidden}
        Icon={UserIcon}
      />
      <Ui.BasketWrapper>{basket}</Ui.BasketWrapper>
    </Ui.BasketAndPromoWrapper>
  );
};
