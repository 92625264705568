import React from 'react';
import {
  LearningCenter as LearningCenterComponent,
  Button,
  LearningCenterSkeleton,
  ComponentLoader,
} from '@elc/common';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import history from '../utils/history';
import { pages } from '../routes/config';
import { useLangagueAwareLearingCenter } from '../hooks/useLangaugeAwareLearingCenter';

const Wrapper = styled.div`
  padding: 20px;
`;

const LearningCenter = (): JSX.Element => {
  const { t } = useTranslation(['app']);
  const centerToDisplay = useLangagueAwareLearingCenter();

  return (
    <Wrapper>
      <ComponentLoader isReady={!!centerToDisplay} skeleton={<LearningCenterSkeleton />}>
        {centerToDisplay && <LearningCenterComponent center={centerToDisplay} />}
      </ComponentLoader>
      <div
        style={{
          background: 'white',
          padding: 50,
          margin: ' 50px 0',
          textAlign: 'center',
          fontSize: 16,
        }}
      >
        <p>{t('learingCenter.content')}</p>
        <div style={{ maxWidth: 200, margin: '0 auto' }}>
          <Button
            text={t('learingCenter.button')}
            onClick={() => history.push(pages.FAQ_CONTENT_CREATORS)}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default LearningCenter;
