import { useMatch } from 'react-router-dom';
import { pages } from '../routes/config';

export const useIsLookPage = (): boolean => {
  const isLookPageFromArtist = useMatch(pages.LOOK_FROM_ARTIST) !== null;
  const isLook = useMatch(pages.BUNDLE) !== null;
  const isLookPageFromLocation = useMatch(pages.LOOK_FROM_LOCATION) !== null;

  return isLookPageFromArtist || isLook || isLookPageFromLocation;
};
