import styled from 'styled-components';

import { media, transparentButton } from '@elc/common';
import backgroundImage from '../../assets/img/salonGroupBackground.png';

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #cccccc;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

export const FakeModal = styled.div`
  position: relative;
  max-width: unset;
  max-height: unset;
  height: 100vh;
  width: 100vw;
  background: white;
  overflow-y: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);

  ${media.sm`
    max-width: 820px;
    max-height: 90vh;
    height: auto;
    width: auto;
    margin: 0 25px;
  `}
`;

export const Header = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.sm`
    padding: 30px 70px;
  `}
`;

export const HeaderDescription = styled.p`
  font-size: 18px;
  margin-bottom: 0;
  ${media.sm`
    font-size: 20px;
  `}
`;

export const List = styled.section`
  padding: 10px 0;
  background: #edeae4;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0;
  position: relative;
  z-index: 1;
  min-height: calc(100% - 210px);
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 83px;
  ${media.sm`
    gap: 10px 32px;
    padding: 10px 70px;
    flex-direction: row;
    margin-bottom: 0;
    
  `};
`;

export const Item = styled.button`
  ${transparentButton}
  padding: 9px 15px 9px 25px;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${media.sm`
    flex: 0 0 calc(50% - 16px);
    max-width: 50%;
    align-items: center;
    padding: 16px 8px;
    width:auto;
  `}
  svg {
    max-width: 40px;
    align-self: flex-start;

    .st0 {
      fill: ${({ theme }) => theme.colors.black};
    }
    ${media.sm`
      display: none;
    `};
  }

  :hover {
    background-color: #120e02;

    svg {
      .st0 {
        fill: ${({ theme }) => theme.colors.white};
      }
    }

    h2 {
      color: white;
    }
    p {
      color: #c4c3c0;
    }
    .triangle {
      border-color: transparent transparent transparent #fff;
    }
  }
`;

export const Triangle = styled.div.attrs({ className: 'triangle' })`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 12px;
  border-color: transparent transparent transparent #000000;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding-right: 4px;
  width: 100%;
  padding-left: 8px;
  ${media.sm`
    padding-left: 0;
  `}
`;

export const GroupPageName = styled.h2`
  font-weight: 900;
  font-size: 28px;
  ${media.sm`
    font-size: 42px;
  `}
`;

export const SalonName = styled.h2`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
`;

export const SalonAddress = styled.p`
  font-size: 15px;
  text-transform: capitalize;
  margin: 0;
  height: 20px;
  word-break: break-word;
  overflow: hidden;
`;

export const SalonLogo = styled.img`
  max-width: 40px;
  margin-right: 12px;

  ${media.sm`
    max-width: 75px;
  `}
`;

export const ScissorsIcon = styled.img`
  max-width: 40px;
  align-self: flex-start;
  ${media.sm`
    display: none;
  `}
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 25px;
  background: #edeae4;

  ${media.sm`
    position: sticky;
  `}
`;

export const AvedaLogo = styled.img``;
