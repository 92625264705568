import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, confirmAccountDeletion, requestAccountDeletion } from '@elc/common';
import { Card } from '../components/card/Card';
import { CardWrapper } from '../components/wrappers/wrapper.styles';

const formGroupStyle = {
  marginBottom: '1rem',
  display: 'flex',
  margin: '20px',
  gap: '20px',
  alignItems: 'center',
};

const labelStyle = {
  display: 'flex',
  width: '170px',
  marginBottom: '0.5rem',
  justifyContent: 'left',
  alignItems: 'center',
};

const inputStyle = {
  width: '100%',
  padding: '0.1rem',
  boxSizing: 'border-box',
  height: '100%',
  marginRight: '1.5rem',
};

const EmailPasswordForm = ({
  sendCredentials,
}: {
  sendCredentials: (credentials: { email: string; password: string }) => void;
}) => {
  const { t } = useTranslation(['app']);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const handleProceed = () => {
    sendCredentials({ email, password });
  };

  return (
    <>
      <h3 className="mb-5">{t('deleteAccount.step1Title')}</h3>

      <div style={formGroupStyle}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
        <label htmlFor="email" style={labelStyle}>
          Email:
        </label>
        <input
          type="email"
          id="email"
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          // @ts-ignore
          style={inputStyle}
          required
        />
      </div>
      <div style={formGroupStyle}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
        <label htmlFor="password" style={labelStyle}>
          Password:
        </label>
        <input
          type="password"
          id="password"
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          // @ts-ignore
          style={inputStyle}
        />
      </div>
      <Button
        text={t('deleteAccount.step1Button')}
        disabled={!email || !password}
        onClick={() => handleProceed()}
      />
    </>
  );
};

const VerificationCodeForm = ({
  email,
  validate,
}: {
  email: string;
  validate: (email, code) => void;
}) => {
  const { t } = useTranslation(['app']);
  const [code, setCode] = React.useState('');

  return (
    <>
      <h3 className="mb-3">{t('deleteAccount.step2Title')}</h3>
      <p>{t('deleteAccount.step2Content')}</p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <input
          type="text"
          placeholder="verification code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          // @ts-ignore
          style={{ padding: '3px', width: '100%', marginRight: '1.5rem' }}
        />
        <Button
          text={t('deleteAccount.step2Button')}
          onClick={() => {
            validate(email, code);
          }}
        />
      </div>
    </>
  );
};

const FinalStep = () => {
  const { t } = useTranslation(['app']);
  return (
    <div>
      <h3>{t('deleteAccount.step3Title')}</h3>
      <p>{t('deleteAccount.step3Content')}</p>
    </div>
  );
};

const DeleteAccount: React.FC = () => {
  const [step, setStep] = React.useState(1);
  const [email, setEmail] = React.useState('');
  const { t } = useTranslation(['app']);

  const sendDeletionRequest = async (credentials: { email: string; password: string }) => {
    setEmail(credentials.email);
    try {
      const response = await requestAccountDeletion(credentials.email, credentials.password);
      if (response.status === 201) {
        setStep(2);
      }
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(
        `Failed to submit the deletion request: ${
          error.response?.data?.response?.[0] || 'An error occurred'
        }`,
      );
    }
  };

  const useVerificationCode = async (userEmail: string, code: string) => {
    try {
      const response = await confirmAccountDeletion(userEmail, code);
      if (response.status === 200) {
        setStep(3);
      }
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(`Error: ${error.response?.data?.response?.[0] || 'An error occurred'}`);
    }
  };

  return (
    <div className="container my-5">
      <h2
        className="mb-5"
        style={{
          letterSpacing: 4,
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {t('deleteAccount.title')}
      </h2>
      <CardWrapper>
        {(() => {
          switch (step) {
            case 1:
              return (
                <Card
                  title=""
                  content={<EmailPasswordForm sendCredentials={sendDeletionRequest} />}
                  className="my-5"
                />
              );
            case 2:
              return (
                <Card
                  title=""
                  content={<VerificationCodeForm email={email} validate={useVerificationCode} />}
                  className="my-5"
                />
              );
            default:
              return <Card title="" content={<FinalStep />} className="my-5" />;
          }
        })()}
      </CardWrapper>
    </div>
  );
};

export default DeleteAccount;
