import React from 'react';
import YouTube from 'react-youtube';
import { TRelatedContent } from '../../typings';
import { analiticsVideo } from '../../utils/googleAnalitics';
import { youtubeIdFromInput } from '../../utils/youtube';
import { Container } from '../Container/Container.styles';
import { VideoContent } from '../LooksList/RelatedContent/VideoContent';

export const Video = ({ video }: { video: TRelatedContent }) => {
  if (!video) {
    return null;
  }

  if (video.resource_type === 'youtube') {
    // some comment
    const youtubeId = youtubeIdFromInput(video.url);
    return (
      <Container>
        <YouTube
          className="iframe"
          videoId={youtubeId}
          opts={{
            width: '100%',
          }}
          onPlay={(event) => analiticsVideo(event, video.resource_type, youtubeId)}
          onPause={(event) => analiticsVideo(event, video.resource_type, youtubeId, false)}
        />
      </Container>
    );
  }

  return (
    <Container>
      <VideoContent item={video} />
    </Container>
  );
};
