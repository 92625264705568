import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLocation, IFetchableItem, ILocation } from '@elc/common';

export const fetchLocation = createAsyncThunk(
  'location/fetchLocationByVanityUrl',
  async (vanityUrl: string, { rejectWithValue }) => {
    try {
      const { data: response } = await getLocation(vanityUrl);

      if (response) {
        return response;
      }
      throw Error();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return rejectWithValue((error as any).response.status);
    }
  },
  {
    condition: (vanityUrl: string, { getState }) => {
      const { location } = getState() as { location: IFetchableItem<ILocation> };
      if (location.item.vanity_url === vanityUrl) {
        return false;
      }
    },
  },
);
