import React from 'react';
import { E2EWrapper } from '../../E2EWrapper/E2EWrapper';
import { TArtist, TCustomArtist } from '../../..';
import avatarProfile from '../../../assets/images/aveda-logo-black.png';
import * as Ui from './Stylist.styles';

export type StylistProps = {
  stylist: TArtist | TCustomArtist;
  noSlide?: boolean;
};

export const Stylist = ({ stylist, noSlide = false, ...styles }: StylistProps) => (
  <E2EWrapper id={`stylist-${stylist.name}`} {...styles}>
    <Ui.Slide noSlide={noSlide}>
      <Ui.CoverPhotoWrapper>
        <Ui.Image src={stylist.picture || avatarProfile} />
      </Ui.CoverPhotoWrapper>
      <Ui.Name>{stylist.name}</Ui.Name>
      <Ui.Name>{stylist.about}</Ui.Name>
    </Ui.Slide>
  </E2EWrapper>
);
