/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import {
  analyticsProductClick,
  analyticsSetPageTypeCategory,
  Banners,
  FeaturedLook,
  LooksCollection,
  OurStylists,
  SAddProductToCart,
  TLook,
  TUseCheckoutFlowType,
  Video,
  FavouriteProducts,
  ILocation,
} from '@elc/common';

export const renderLocationPageElement = (
  element: { name: string; item: any },
  addProductToCart: SAddProductToCart,
  handleShopNowClick: (look: TLook) => void,
  locationVanityUrl: string,
  redirectToAveda: TUseCheckoutFlowType,
): JSX.Element | null => {
  switch (element.name) {
    case 'bundle': {
      const look = element.item;

      return (
        <FeaturedLook
          look={look}
          addProductToCart={addProductToCart}
          onProductDetailsClick={analyticsProductClick}
          onModalClose={analyticsSetPageTypeCategory}
          handleShopNowClick={handleShopNowClick}
        />
      );
    }
    case 'ourStylists': {
      const { stylists } = element.item;

      return <OurStylists stylists={stylists} />;
    }
    case 'banners': {
      const { banners } = element.item;
      return <Banners banners={banners} onClick={redirectToAveda} />;
    }
    case 'shearableLink': {
      const { banners } = element.item;
      return <Banners banners={banners} onClick={redirectToAveda} />;
    }
    case 'video': {
      const { video } = element.item;

      return <Video video={video[0]} />;
    }
    case 'bundlesCollection': {
      const { looks } = element.item;

      const onlyVisibleLooks = (look: TLook) =>
        !look.is_private && !look.is_draft && look.hausmart_type !== 'SINGLE_PRODUCT';

      if (!looks.filter(onlyVisibleLooks).length) {
        return null;
      }

      return (
        <LooksCollection
          looks={looks.filter(onlyVisibleLooks)}
          locationVanityUrl={locationVanityUrl}
        />
      );
    }
    case 'favouriteProducts': {
      const { singleProductLooks, custom_title } = element.item;

      const onlyVisibleLooks = (look: TLook) =>
        !look.is_private && !look.is_draft && look.is_single_product;

      if (!singleProductLooks.filter(onlyVisibleLooks).length) {
        return null;
      }

      return (
        <FavouriteProducts
          looks={singleProductLooks.filter(onlyVisibleLooks)}
          locationVanityUrl={locationVanityUrl}
          custom_title={custom_title}
        />
      );
    }
    default:
      return null;
  }
};

export const getLocationAddress = (location: ILocation) =>
  ` ${location.address.street_name.toLowerCase()}, ${location.address.post_town.toLowerCase()}, ${location.address.state?.toUpperCase()}`;
