import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCartItem } from '../../typings';
import { analyticsAddToBag } from '../../utils/googleAnalitics';
import * as basketActions from './basket.actions';

export interface IEntityBasket<T> {
  items: Array<T>;
  lastAddedItems: TCartItem[];
  lookFromLastItemWasAdded: string;
  isPending: boolean;
  artistId: string;
}

const initialState: IEntityBasket<TCartItem> = {
  items: JSON.parse(localStorage.getItem('basket') || '[]') || [],
  lastAddedItems: [],
  lookFromLastItemWasAdded: Array.isArray(
    JSON.parse(localStorage.getItem('lookFromLastItemWasAdded') || '[]'),
  )
    ? ''
    : JSON.parse(localStorage.getItem('lookFromLastItemWasAdded') || '[]') || '',
  artistId: JSON.parse(localStorage.getItem('artistId') || '[]') || '',
  isPending: false,
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addToBasket: (state, action: PayloadAction<TCartItem>) => {
      const index = state.items.findIndex(
        (item) => item.uid === action.payload.uid && item.bundleId === action.payload.bundleId,
      );

      if (index >= 0) {
        state.items[index].quantity += 1;
      } else {
        state.items = state.items.concat({ ...action.payload, quantity: 1 });
      }
      state.lastAddedItems = [...state.lastAddedItems, { ...action.payload, quantity: 1 }];
      state.lookFromLastItemWasAdded = action.payload.bundleId;
      localStorage.setItem('basket', JSON.stringify(state.items));
      localStorage.setItem(
        'lookFromLastItemWasAdded',
        JSON.stringify(state.lookFromLastItemWasAdded),
      );

      analyticsAddToBag(action.payload);
    },
    lowerTheAmount: (state, action: PayloadAction<TCartItem>) => {
      const index = state.items.findIndex(
        (item) => item.uid === action.payload.uid && item.bundleId === action.payload.bundleId,
      );
      if (state.items[index].quantity > 1) {
        state.items[index].quantity -= 1;
      } else {
        state.items = state.items.filter((_, i) => i !== index);
      }
      localStorage.setItem('basket', JSON.stringify(state.items));
    },
    removeFromBasket: (state, action: PayloadAction<TCartItem>) => {
      const index = state.items.findIndex(
        (item) => item.uid === action.payload.uid && item.bundleId === action.payload.bundleId,
      );

      state.items = state.items.filter((_, i) => i !== index);
      localStorage.setItem('basket', JSON.stringify(state.items));
    },
    clearLastAddedItems: (state) => {
      state.lastAddedItems = [];
    },
    isPendingBasket: (state, action) => {
      state.isPending = action.payload;
    },
    clearBasket: (state) => {
      state.items = [];
      state.lastAddedItems = [];
      state.isPending = true;
      localStorage.setItem('basket', JSON.stringify([]));
      localStorage.setItem('artistId', JSON.stringify([]));
    },
    setArtistId: (state, action) => {
      state.artistId = action.payload;
      localStorage.setItem('artistId', JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(basketActions.checkout.fulfilled, (state) => {
      state.items = [];
      state.lastAddedItems = [];
      localStorage.setItem('basket', JSON.stringify([]));
    });
  },
});

export const basketAction = {
  ...basketSlice.actions,
  ...basketActions,
};

export const basketReducer = basketSlice.reducer;
