import React, { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { TRelatedContent, TUseCheckoutFlowType } from '../../typings';

import * as Ui from './Banners.styles';
import { breakpoints } from '../../utils/rwd';
import { analyticsPromoView } from '../../utils/googleAnalitics';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';

type BannersProps = {
  banners: TRelatedContent[];
  onClick: TUseCheckoutFlowType;
};

// TODO make banners clickable and add promoClick() to it's onClick function
export const Banners = ({ banners, onClick }: BannersProps) => {
  const isSmallerThenLg = useMediaQuery({
    query: `(max-width: ${breakpoints.lg}px)`,
  });

  const getImageUrl = useCallback(
    (banner: TRelatedContent) => {
      const defaultImage = banner.mobile_url || banner.url;

      return (isSmallerThenLg ? banner.mobile_url : banner.url) || defaultImage;
    },
    [isSmallerThenLg],
  );

  useEffect(() => {
    analyticsPromoView(banners.map((b, idx) => [b, idx + 1]));
  }, []);

  const handleBannerClick = (link: string | null) => {
    if (link) {
      onClick({ path: link, source: 'banner' });
    }
  };
  if (banners.length) {
    return (
      <Ui.Container>
        {banners.map((banner, idx) => (
          <E2EWrapper id={`salonBanner-${idx}`} key={banner.name}>
            <Ui.Banner
              role="button"
              src={getImageUrl(banner)}
              onClick={() => handleBannerClick(banner.link)}
            />
          </E2EWrapper>
        ))}
      </Ui.Container>
    );
  }

  return null;
};
