import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media, Terms as TermsComponent, useScrollToTop } from '@elc/common';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGlobalState, useThunkDispatch } from '../store';
import { fetchTerms } from '../store/actions/terms.actions';
import image from '../assets/img/privacy.png';

const Wrapper = styled.div`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Card = styled.div`
  width: 100%;
  margin: 20px 0px;
  background: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;

  ${media.md`
    margin: 0px;
    width: 90%;
  `}

  ${media.xl`
    margin: 0px;
    width: 75%;
  `}
`;

type TermsRouteParams = { tabName: string };

const Terms: React.FC = () => {
  const { tabName = '' } = useParams<TermsRouteParams>();

  const dispatch = useThunkDispatch();
  const terms = useGlobalState((state) => state.terms.item);
  const navigate = useNavigate();
  const { t } = useTranslation(['app']);

  useScrollToTop();

  useEffect(() => {
    dispatch(fetchTerms());
  }, []);

  return (
    <Wrapper>
      <img
        alt="banner"
        src={image}
        style={{ width: '100%', aspectRatio: '16/6', objectFit: 'cover' }}
      />
      <Card className="my-5">
        <h2>{t('terms')}</h2>
        <TermsComponent
          terms={terms}
          activeTabName={tabName}
          onChange={(name) =>
            navigate(`/terms/${name}`, {
              state: {
                from: '',
              },
            })
          }
        />
      </Card>
    </Wrapper>
  );
};

export default Terms;
