import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { SAddProductToCart, TLook, SProductDetailsClick } from '../../typings';
import { LookProductsList } from '../LookProductsList/LookProductsList';
import * as Ui from './FeaturedLook.styles';
import { breakpoints } from '../../utils/rwd';
import { LookListItem } from '../LooksList/LooksList';

export type FeaturedLookProps = {
  look: TLook;
  addProductToCart: SAddProductToCart;
  onModalClose?: () => void;
  onProductDetailsClick?: SProductDetailsClick;
  handleShopNowClick;
};

export const FeaturedLook = ({
  look,
  onModalClose,
  onProductDetailsClick,
  addProductToCart,
  handleShopNowClick,
}: FeaturedLookProps) => {
  const isSmallerThenLg = useMediaQuery({
    query: `(max-width: ${breakpoints.lg}px)`,
  });

  if (isSmallerThenLg) {
    return (
      <Ui.LookListItemWrapper>
        <LookListItem
          compactView={false}
          look={look}
          onShopNowClick={() => handleShopNowClick(look)}
          addProductToCart={addProductToCart}
        />
      </Ui.LookListItemWrapper>
    );
  }

  return (
    <Ui.Wrapper>
      <LookProductsList
        onModalClose={onModalClose}
        onProductDetailsClick={onProductDetailsClick}
        onAddToCart={addProductToCart(look.short_id)}
        look={look}
      />
    </Ui.Wrapper>
  );
};
