import { TCheckoutSource } from '../..';

type ContentItem = {
  text: string;
  link: string;
  isSubCategory?: boolean;
};

export function isContentItem(object: unknown): object is ContentItem {
  return (
    typeof object === 'object' &&
    object !== null &&
    typeof (object as ContentItem).text === 'string' &&
    typeof (object as ContentItem).link === 'string' &&
    (typeof (object as ContentItem).isSubCategory === 'boolean' ||
      (object as ContentItem).isSubCategory === undefined)
  );
}

export type ContentObject = {
  mainCategory: {
    link: string;
    text: string;
  };
  items: ContentItem[];
};

export function isContentObject(object: unknown): object is ContentObject {
  return (
    typeof object === 'object' &&
    object !== null &&
    typeof (object as ContentObject).mainCategory === 'object' &&
    (object as ContentObject).mainCategory !== null &&
    typeof (object as ContentObject).mainCategory.link === 'string' &&
    typeof (object as ContentObject).mainCategory.text === 'string' &&
    Array.isArray((object as ContentObject).items) &&
    (object as ContentObject).items.every(isContentItem)
  );
}

export type Content = ContentObject[] | null;

export function isContent(value: unknown): value is Content {
  if (value === null) return true;
  if (!Array.isArray(value)) return false;

  return value.every((item) => isContentObject(item));
}

export type LinkType = {
  path: string;
  label: string;
  source: TCheckoutSource;
  content: Content;
};

export const salonTopbarLinks: Array<LinkType> = [
  {
    path: '/products/17976/best-sellers',
    source: 'header',
    label: 'bestsellers',
    content: null,
  },
  {
    path: '/hair-care',
    source: 'header',
    label: 'hair & scalp',
    content: [
      {
        mainCategory: { link: '/#', text: 'Category' },
        items: [
          { text: 'new products', link: '/new-hair-care', isSubCategory: true },
          { text: 'all hair & scalp', link: '/hair-care', isSubCategory: true },
          { text: 'shampoo', link: '/products/5311/hair-care/shampoo' },
          {
            text: 'conditioner',
            link: '/products/5293/hair-care/conditioner',
          },
          { text: 'scalp care', link: '/products/19384/hair-care/scalp-care' },
          {
            text: 'hair treatments',
            link: '/products/5303/hair-care/hair-treatments',
          },
          {
            text: 'hair & scalp oils',
            link: '/products/17753/hair-care/hair-scalp-oils',
          },
          {
            text: 'dry shampoo',
            link: '/products/17811/hair-care/dry-shampoo-and-second-day-hair',
          },
          { text: 'hair color', link: '/hair-color' },
          { text: 'hair serum', link: '/products/31802' },
          {
            text: "men's hair care",
            link: '/products/5310/hair-care/mens-hair-care',
          },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Best For' },
        items: [
          { text: 'dry hair', link: '/hair-care/products-for-dry-hair' },
          { text: 'oily hair & scalp', link: '/oily-hair-and-scalp' },
          {
            text: 'damaged hair',
            link: '/botanical-repair-hair-damage-treatment',
          },
          {
            text: 'hair loss',
            link: '/hair-care/invati-advanced-thinning-hair-solutions',
          },
          { text: 'color care', link: '/hair-care/color-care-treated-enrich' },
          { text: 'frizzy hair', link: '/hair-care/frizzy-hair' },
          {
            text: 'coily, curly & wavy hair',
            link: '/textured-hair-products',
          },
          { text: 'travel', link: '/products/17785/hair-care/travel' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Featured Collections' },
        items: [
          {
            text: 'be curly advanced™',
            link: '/hair-care/be-curly-advanced-hair-products-for-curly-hair',
          },
          {
            text: 'botanical repair™',
            link: '/botanical-repair-hair-damage-treatment',
          },
          {
            text: 'nutriplenish™',
            link: '/hair-care/nutriplenish-moisturize-dry-hair',
          },
          {
            text: 'invati advanced™',
            link: '/hair-care/invati-advanced-thinning-hair-solutions',
          },
          {
            text: 'scalp solutions',
            link: '/products/scalp-solutions-dry-scalp-treatment',
          },
          { text: 'shampure™', link: '/hair-care/shampure' },
          { text: 'rosemary mint', link: '/rosemary-mint-products' },
          { text: 'all collections', link: '/shop-all-collections', isSubCategory: true },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Gifts' },
        items: [
          { text: 'all gifts', link: '/products/7479/gifts', isSubCategory: true },
          { text: 'by occasion', link: '/thank-you-gift-ideas' },
          { text: 'liters', link: '/products/17784/hair-care/liters', isSubCategory: true },
        ],
      },
    ],
  },
  {
    path: '/styling',
    source: 'header',
    label: 'styling',
    content: [
      {
        mainCategory: { link: '/#', text: 'Category' },
        items: [
          { text: 'all styling', link: '/styling', isSubCategory: true },
          { text: 'hair spray', link: '/products/5386/styling/hair-spray' },
          { text: 'hair tonic', link: '/products/17769/styling/hair-tonic' },
          { text: 'style-prep™', link: '/products/17768/styling/style-prep' },
          { text: 'texture & hold', link: '/products/17770/styling/cream' },
          { text: 'heat protectant', link: '/products/22901/styling/blow-dry-spray' },
          { text: 'hair brushes', link: '/products/17775/styling/hair-brushes' },
          { text: "men's styling products", link: '/products/17776/styling/mens' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Best For' },
        items: [
          { text: 'smoother hair', link: '/hair-care/smoother-straight-hairstyle' },
          { text: 'thicker hair', link: '/hair-care/thicker-hair' },
          { text: 'dry hair', link: '/hair-care/products-for-dry-hair' },
          { text: 'damaged hair', link: '/hair-care/damaged-hair-repair' },
          { text: 'coily, curly & wavy hair', link: '/textured-hair-products' },
          { text: 'hair volume', link: '/hair-care/pure-abundance-fine-hair' },
          { text: 'shine', link: '/products/5388/styling/shine-enhancers' },
          { text: 'trending hairstyles', link: '/trending-hairstyles' },
          { text: 'travel', link: '/products/17785/hair-care/travel' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Featured Collections' },
        items: [
          {
            text: 'be curly advanced™',
            link: '/hair-care/be-curly-advanced-hair-products-for-curly-hair',
          },
          { text: 'botanical repair™', link: '/botanical-repair-hair-damage-treatment' },
          { text: 'nutriplenish™', link: '/hair-care/nutriplenish-moisturize-dry-hair' },
          { text: 'invati advanced™', link: '/hair-care/invati-advanced-thinning-hair-solutions' },
          { text: 'smooth infusion™', link: '/hair-care/anti-frizz-smooth-infusion' },
          { text: 'control', link: '/styling/control-hairspray-gel' },
          { text: 'all collections', link: '/shop-all-collections', isSubCategory: true },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Gifts' },
        items: [
          { text: 'all gifts', link: '/products/7479/gifts', isSubCategory: true },
          { text: 'by occasion', link: '/thank-you-gift-ideas' },
        ],
      },
    ],
  },
  {
    path: '/skin-care',
    source: 'header',
    label: 'skin & body',
    content: [
      {
        mainCategory: { link: '/#', text: 'Category' },
        items: [
          { text: 'all skin care', link: '/skin-care', isSubCategory: true },
          { text: 'face cleanser', link: '/products/5356/skincare/face-cleanser' },
          { text: 'face toner', link: '/products/7570/skincare/face-toner' },
          { text: 'exfoliator', link: '/products/17734/skincare/exfoliator' },
          { text: 'face serum', link: '/products/17735/skincare/face-serum' },
          { text: 'eye cream', link: '/products/17736/skincare/eye-creme' },
          { text: 'moisturizer', link: '/products/6743/skincare/moisturizer' },
          { text: 'lip treatment & balm', link: '/products/17738/skincare/lip-treatment' },
          { text: 'face mask', link: '/products/17739/skincare/face-mask' },
          {
            text: "men's skin & shave care",
            link: '/products/17740/skincare/mens-simu-shave-care',
          },
          { text: 'all body care', link: '/body-care', isSubCategory: true },
          { text: 'body cleanser', link: '/products/5199/body-cleanser' },
          { text: 'body scrub', link: '/product/17755/77163/body/scrub/cherry-almond-body-scrub' },
          { text: 'body oils', link: '/products/14593/body-oils' },
          { text: 'bath', link: '/products/17762/body/bath' },
          { text: 'lotion', link: '/products/17757/body/lotion' },
          { text: 'hand & foot care', link: '/products/17759/body/hand-foot-care' },
          { text: 'tea', link: '/products/5210/body/tea' },
          { text: "men's body care", link: '/products/17780/men/body-care' },
          { text: 'all makeup', link: '/makeup-products', isSubCategory: true },
          { text: 'lip', link: '/products/5336/makeup/lips' },
          { text: 'face', link: '/products/5335/face' },
          { text: 'all aroma', link: '/aroma-products', isSubCategory: true },
          { text: 'candles', link: '/products/5346/pure-fumetm/candles' },
          { text: 'essential oils', link: '/products/5350/pure-fumetm/essential-oils' },
          { text: 'chakra™', link: '/body/chakra-balance-spray' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Concern' },
        items: [
          { text: 'dry skin', link: '/skin-care/hydrate-dry-skin' },
          { text: 'oily', link: '/skin-care/balance-oily-skin' },
          {
            text: 'lift & firm',
            link: '/product/17735/43500/skin-care/serum/tulasara-firm-concentrate#/shade/1_fl_oz%2F30_ml',
          },
          { text: 'skin radiance', link: '/skin-care/increase-radiance' },
          { text: 'brighten skin', link: '/skin-care/visibly-brighten' },
          { text: 'sensitive skin', link: '/skin-care/soothe-sensitive-skin' },
          { text: 'acne', link: '/skin-care/outer-peace-acne-care' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Featured Collections' },
        items: [
          { text: 'botanical kinetics™', link: '/skin-care/botanical-kinetics-dry-skin-treatment' },
          { text: 'hand & foot relief™', link: '/body/hand-foot-relief-moisturizing-cream-lotion' },
          { text: 'rosemary mint', link: '/rosemary-mint-products#/type/body' },
          { text: 'feed my lips™', link: '/makeup/feed-my-lips' },
          { text: 'comforting tea', link: '/products/5210/body/tea' },
          { text: 'all collections', link: '/shop-all-collections', isSubCategory: true },
        ],
      },
    ],
  },
  {
    path: '/mens-products',
    source: 'header',
    label: "men's",
    content: [
      {
        mainCategory: { link: '/#', text: 'Category' },
        items: [
          { text: 'all mens', link: '/mens-products', isSubCategory: true },
          { text: 'hair care', link: '/products/5310/hair-care/mens-hair-care' },
          { text: 'styling', link: '/products/17776/styling/mens' },
          {
            text: "men's skin & shave care",
            link: '/products/17740/skincare/mens-skin-shave-care',
          },
          { text: 'body care', link: '/products/17780/mens/body-care' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Best For' },
        items: [
          { text: "men's hair loss", link: '/hair-care/men-invati-thinning-hair-solutions' },
          { text: 'soothe scalp', link: '/men-soothe-scalp' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Featured Collections' },
        items: [
          { text: 'invati men™', link: '/hair-care/men-invati-thinning-hair-solutions' },
          { text: 'aveda men pure-formance™', link: '/men/pure-formance-hair-products' },
          { text: 'rosemary mint', link: '/rosemary-mint-products' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Gifts' },
        items: [
          { text: 'all gifts', link: '/products/7479/gifts', isSubCategory: true },
          { text: 'by occasion', link: '/thank-you-gift-ideas' },
        ],
      },
    ],
  },
  {
    path: '/hair-quiz/find-best-haircare-products',
    label: 'hair quiz',
    source: 'header',
    content: null,
  },
  {
    path: '/csr-initiatives',
    source: 'header',
    label: 'our story',
    content: [
      {
        mainCategory: { link: '/#', text: 'Category' },
        items: [
          { text: 'discover aveda', link: '/csr-initiatives' },
          { text: 'high performance ingredients', link: '/green-chemistry-vegan-beauty' },
          { text: 'environmental leadership', link: '/carbon-neutrality-green-initiatives' },
          { text: 'mission', link: '/mission-giving-back' },
          { text: 'heritage', link: '/horst-rechelbacher-aveda-history' },
        ],
      },
      {
        mainCategory: { link: '/#', text: 'Education & Artistry' },
        items: [
          { text: 'artistry in action', link: '/Congress-2023' },
          { text: 'blog', link: '/living-aveda' },
          { text: 'how-to videos', link: '/hair-how-to' },
        ],
      },
    ],
  },
  {
    path: '/exclusive-offers',
    source: 'header',
    label: 'offers',
    content: null,
  },
  {
    path: '/services',
    source: 'header',
    label: 'services',
    content: [
      {
        mainCategory: { link: '/#', text: 'Category' },
        items: [
          { text: 'all services', link: '/services' },
          { text: 'store services', link: '/store-booking-services' },
          { text: 'salon services', link: '/salon-services' },
        ],
      },
    ],
  },
];
