import styled from 'styled-components';
import { media } from '../../../../utils/rwd';

export const Logo = styled.img<{ $fullWidth: boolean; $shouldAddBackground: boolean }>`
  height: ${({ $fullWidth }) => ($fullWidth ? '55px;' : '30px')};
  border: 3px solid #120e02;
  background-color: ${({ $shouldAddBackground }) =>
    $shouldAddBackground ? '#120e02' : 'transparent'};
  margin-right: 10px;
  justify-self: start;

  ${media.sm`
    margin-right: 20px;
  `}
`;
