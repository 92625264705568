import styled from 'styled-components';
import { media } from '@elc/common';

export const PaddingWrapper = styled.div`
  padding: 18px;
  ${media.md.down`
    padding: 8px;
  `}
`;

export const CardWrapper = styled.div`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
