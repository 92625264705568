import styled from 'styled-components';
import { breakpoints, Header } from '@elc/common';

export const Wrapper = styled.div`
  background: #f1f3f6;
`;

export const PageBackground = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background: #f1f3f6;
`;

export const Page = styled.div`
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  height: 100%;
  min-height: calc(100vh - 380px);
  background: #f1f3f6;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled(Header)`
  top: 0;
  .header_brandLogo {
    height: 45%;
  }
`;
