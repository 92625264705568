import React, { FC, useCallback } from 'react';
import { useAvedaRedirect } from '../../../../../../aveda/src/hooks/useAvedaRedirect';
import { Content, ContentObject } from '../../salonTopbarLinks';
import * as Ui from './HoverBar.styles';

type HoverBarProps = {
  content: Exclude<Content, null>;
  linksSmaller: boolean;
  closeHoverBar: () => void;
};

export const HoverBar: FC<HoverBarProps> = ({ content, linksSmaller, closeHoverBar }) => {
  const redirectToAveda = useAvedaRedirect();
  const handleLinkClick = useCallback((path: string) => {
    redirectToAveda({ path, clearCart: false });
  }, []);

  return (
    <Ui.HoverBarWrapper>
      <Ui.CategoriesList>
        <Ui.CloseIcon onClick={closeHoverBar} />
        {content.map((object: ContentObject) => (
          <Ui.Category key={object.mainCategory.text}>
            <Ui.CategoryTitle
              $isSmall={linksSmaller}
              onClick={() => handleLinkClick(object.mainCategory.link)}
            >
              {object.mainCategory.text}
            </Ui.CategoryTitle>

            <Ui.CategoryContentList>
              {object.items.map((item) => {
                const Wrapper = Ui[item.isSubCategory ? 'SubCategoryWrapper' : 'LinkWrapper'];

                return (
                  <Wrapper key={item.link} onClick={() => handleLinkClick(item.link)}>
                    <Ui.Link $isSmall={linksSmaller}>{item.text}</Ui.Link>
                  </Wrapper>
                );
              })}
            </Ui.CategoryContentList>
          </Ui.Category>
        ))}
      </Ui.CategoriesList>
    </Ui.HoverBarWrapper>
  );
};
