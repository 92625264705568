import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import * as Ui from './BackNavigation.styles';

interface IBackNavigationProps {
  label: string;
  path: string;
}

export const BackNavigation: FC<IBackNavigationProps> = ({ path, label, ...rest }) => (
  <Ui.Wrapper {...rest}>
    <>{label && <Link to={path}>Back to {label}</Link>}</>
  </Ui.Wrapper>
);
